import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react'
import TestCaseHeader from '../testCaseHeader';
import { Controller, useForm } from 'react-hook-form';
import Dropdown from '../../shared/components/Dropdown';
import PayaptTextField from '../../shared/components/TextField';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { addRoutingNUmberVAlidation } from './utils';
import _ from 'lodash';
import { useAddingRtnNumberMutation, useGetMasterRtnQuery, useRountingNumberTypeQuery } from '../../redux/customers/customer.api';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';

const styles = {
  menuProps: {
    sx: {
      '.MuiPaper-root': {
        maxHeight: '40%',
      },
    },
  },
}
const AddRouting = (props) => {
  const {setModalVisibility = () => { }} = props;
  const resolver = yupResolver(addRoutingNUmberVAlidation);
  const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { customerId } = useSelector((state) => state?.common);
  const { value, customer } = useContext(DropdownValueContext);
  const Snackbar = useContext(SnackbarContext);
  const { control, register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver,
    defaultValues: {
      paymentServiceId: '',
      routingNumberType: '',
      routingNumber: '',
      bankName: '',
      bankLocation: '',
      masterRtnId: ''
    }
  });
  const { paymentServices } = useSelector((state) => state?.common);
  // console.log("paymentServices", paymentServices);
  const applicableServices = paymentServices.filter(service => service.isApplicable);
  const { data: routingOptions } = useRountingNumberTypeQuery();
  const { data:masterRtnOptions } = useGetMasterRtnQuery({paymentServiceId:value,customerId:  customerId });
  const [addingRtnNumber] = useAddingRtnNumberMutation();
  // console.log("masterRtnOptions",masterRtnOptions);
  // console.log("routingOptions", routingOptions);
  const [paymentList, setPaymentList] = useState();
  const [routingList, setRoutingList] = useState();
  const [masterList, setMasterList] = useState();

  const watchFieldForOrganization = watch('routingNumberType');
  // console.log("masterList", masterList);


  const fetchPaymentService = () => {
    const paymentTypes = _.map(applicableServices, (m) => ({
      id: m.paymentServiceId,
      value: m.paymentServiceName,
      label: m.paymentServiceName,
    }));
    setPaymentList(paymentTypes);
  };

  const fetchRoutingNumber = () => {
    const routingTypes = _.map(routingOptions, (m) => ({
      id: m.routingNumberType,
      value: m.routingNumberTypeValue,
      label: m.routingNumberTypeValue,
    }));
    setRoutingList(routingTypes)
  };
  
  const fetchMasterRTN = () => {
    const masterTypes = _.map(masterRtnOptions, (m) => ({
      id: m.masterRtnId,
      value: m.masterRtn,
      label: m.masterRtn
    }));
    setMasterList(masterTypes)
  };


  React.useEffect(() => {
    fetchPaymentService();
    fetchRoutingNumber();
    fetchMasterRTN();
  }, [paymentServices, routingOptions]);

  const handleAddRouting = async(data) => {
    data.paymentServiceId = +data.paymentServiceId;
    data.routingNumberType = +data.routingNumberType;
    if (watchFieldForOrganization !== 1 ) {
      data.masterRtnId = +data.masterRtnId;
    } else {
      // If it's not provided, ensure it doesn't remain as an empty string
      delete data.masterRtnId;
    }
    data.routingNumber = [data.routingNumber]
    // console.log("ADDRTN", data);
    try {
      const addValues = _.filter(rtnNumberList, d => d.isNew).map(newRow => newRow.routingNumber);
      // const xElpayload = {
      //     customerId: customerId,
      //     routingNumber: [data?.routingNumber],
      //     paymentServiceId: value,
      //     bankName:"",
      //     bankLocation:"",
      //     masterRtnId:232,
      //     routingNumberType:2
      // }
      await addingRtnNumber(data).unwrap().then((res) => {
          // console.log("RESSS", res);
          if (res?.status === "success") {
              Snackbar.displayMsg("Routing Number added or updated succesfully", ALERT_SEVERITY.SUCCESS);
              setModalVisibility(false)
              // closeModal();
              // setValue('routingNumber', "")
              // viewRtnNumberList(initialPageOptions);
          } else {
              Snackbar.displayMsg("Routing Number is Failed to added or updated", ALERT_SEVERITY.ERROR);
          }

      })
  } catch (error) {
      if (error?.status === 400) {
          Snackbar.displayMsg(error?.data?.message, ALERT_SEVERITY.ERROR);
      } else {
          Snackbar.displayMsg("Routing Number is Failed to added or updated", ALERT_SEVERITY.ERROR);
      }
  }
  }
  return (
    <Box>
      <TestCaseHeader
        title='Add Routing Number'
      />
      <Box px={2.5} sx={{ overflowY: 'auto', maxHeight: '73vh', marginTop: '2%' }}>
        <Grid item xs={6} container>
          <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
            Select Payment Service <font color="red">*</font>
          </Typography>
          <Controller
            control={control}
            name={"paymentServiceId"}
            render={({ field }) => (
              <Dropdown
                inputRef={register('paymentServiceId', { required: true })}
                {...field}
                placeholder='Select Payment Service'
                margin="dense"
                className="user-dropdown"
                sx={{ marginTop: '7px' }}
                MenuProps={styles.menuProps}
                style={{ width: '120%', height: '56px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={paymentList}
                error={!!errors['paymentServiceId']}
                helperText={errors['paymentServiceId']?.message}
              />
            )}
          />
          {
            errors.paymentServiceId && (
              <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>
                Payment Service is required
              </div>
            )
          }
        </Grid>
        <br />
        <Grid item xs={6} container >
          <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
            Select Rounting Number Type <font color="red">*</font>
          </Typography>
          <Controller
            control={control}
            name={"routingNumberType"}
            render={({ field }) => (
              <Dropdown
                inputRef={register('routingNumberType', { required: true })}
                {...field}
                placeholder='Select Routing Number Type'
                margin="dense"
                className="user-dropdown"
                sx={{ marginTop: '7px' }}
                MenuProps={styles.menuProps}
                style={{ width: '120%', height: '56px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={routingList}
                error={!!errors['routingNumberType']}
                helperText={errors['routingNumberType']?.message}
              />
            )}
          />
          {
            errors.routingNumberType && (
              <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>
                Routing Number Type is required
              </div>
            )
          }
        </Grid>
        <br />
        {(watchFieldForOrganization === 2 || watchFieldForOrganization === 3) &&
          <Grid item xs={6} container>
            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
              Select Master RTN <font color="red">*</font>
            </Typography>
            <Controller
              control={control}
              name={"masterRtnId"}
              render={({ field }) => (
                <Dropdown
                  inputRef={register('masterRtnId', { required: false })}
                  {...field}
                  placeholder='Select Master RTN'
                  margin="dense"
                  className="user-dropdown"
                  sx={{ marginTop: '7px' }}
                  MenuProps={styles.menuProps}
                  style={{ width: '120%', height: '56px' }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  options={masterList}
                  error={!!errors['masterRtnId']}
                  helperText={errors['masterRtnId']?.message}
                // valueKey='paymentServiceId'
                // labelKey='customerName'
                />
              )}
            />
            {errors.masterRtnId && (
              <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>
                Master RTN Type is required
              </div>
            )}
          </Grid>
        }
        {(watchFieldForOrganization === 2 || watchFieldForOrganization === 3) &&
          <br />
        }
        <Grid>
          <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
            Routing Number <font color="red">*</font>
          </Typography>
          <Controller
            control={control}
            name={'routingNumber'}
            render={({ field }) => (
              <PayaptTextField
                {...register('routingNumber')}
                {...field}
                name="routingNumber"
                placeholder="Enter Routing Number"
                margin="dense"
                sx={{ width: '60%' }}
                error={!!errors['routingNumber']}
                helperText={errors['routingNumber']?.message}

              />
            )}
          />
        </Grid>

        <Grid>
          <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
            Bank Name <font color="red">*</font>
          </Typography>
          <Controller
            control={control}
            name={'bankName'}
            render={({ field }) => (
              <PayaptTextField
                {...register('bankName')}
                {...field}
                name="bankName"
                placeholder="Enter Bank Name"
                margin="dense"
                sx={{ width: '60%' }}
                error={!!errors['bankName']}
                helperText={errors['bankName']?.message}

              />
            )}
          />
        </Grid>

        <Grid>
          <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
            Bank Location <font color="red">*</font>
          </Typography>
          <Controller
            control={control}
            name={'bankLocation'}
            render={({ field }) => (
              <PayaptTextField
                {...register('bankLocation')}
                {...field}
                name="bankLocation"
                placeholder="Enter Bank Location"
                margin="dense"
                sx={{ width: '60%' }}
                error={!!errors['bankLocation']}
                helperText={errors['bankLocation']?.message}

              />
            )}
          />
        </Grid>

      </Box>
      <Box mt={3} px={2.5}>
        <Grid container justifyContent="flex-start"  >
          <MUIButton
            startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
            title="Cancel"
            sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
            color="inherit"
          // onClick={handleReset}
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <MUIButton
            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
            title="Save"
            onClick={handleSubmit(handleAddRouting)}
          />
        </Grid>
      </Box>
    </Box>
  )
}

export default AddRouting;