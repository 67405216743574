import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import TestCaseHeader from '../testCaseHeader';
import PayaptTable from '../../shared/components/Table';
import { settlementAccount_columns } from '../../constants/constants';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ALERT_SEVERITY, APP_ROUTE_KEYS, greyBorderColor, primaryColor } from '../../shared/constants/constants';
import { useNavigate } from 'react-router-dom';
import useModal from '../../shared/hooks/useModal.hook';
import AntModal from '../../shared/components/Modal/AntModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getSettlementFilterOptions, settlementActTransFilter } from '../homeScreens/TestRun/constants';
import Filter from '../../shared/components/Filter.popup';
import { useGetSettlementAccountListMutation, useUpdateAccountBalanceMutation } from '../../redux/customers/customer.api';
import _ from 'lodash';
import WrapperComponent from '../../shared/components/wrapperComponent';
import { convertToLocalTime } from '../../shared/utils';
import { Modal } from 'antd';
import PayaptTextField from '../../shared/components/TextField';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const SettlementAccount = () => {
    const navigate = useNavigate();
    const Snackbar = useContext(SnackbarContext);
    const { value, updateClickedCard, dropValue } = useContext(DropdownValueContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { open, openModal, closeModal } = useModal();
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const { customerId } = useSelector((state) => state?.common);
    const [searchTerm, setSearchTerm] = useState("");

    const columns = settlementAccount_columns;

    const initialPageOptions = {
        "customerId": customerId,
        "search": null,
        "page": 0,
        "size": pageSize,
        "fromDate": null,
        "toDate": null,
        "debitOrCredit": null,
        "paymentServiceId": (userRole?.name === 'Super Admin' ? dropValue : value )
    }
    // console.log("initialPageOptions", initialPageOptions);

    const [filters, setFilters] = React.useState(settlementActTransFilter || {});
    const [filterOptions, setFilterOptions] = useState(getSettlementFilterOptions([]));
    const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
    const [isModalVisible, setModalVisibility] = useState(false);


    const [getSettlementList, { data, isLoading }] = useGetSettlementAccountListMutation();

    const [paymentMethod, setPaymentMethod] = useState('');
    const [amount, setAmount] = useState();
    const { control, handleSubmit, formState: { errors }, setValue } = useForm();
    // console.log("amount", amount);

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);
        // console.log(event.target.value);
    };

    const handleAmountChange = (event) => {
        // setAmount(event.target.value);
        // console.log(event.target.value);
        const value = event.target.value;
        if (value >= 0) {
            setValue('amount', value); // Set the value using React Hook Form's setValue
        }
    };

    const handleCreditDedit = (isVisible) => {
        setModalVisibility(isVisible)
    }


    const [updateAccountBalance] = useUpdateAccountBalanceMutation();

    const handleAccountBalance = async (data) => {
        // console.log("Dataaaa",data);
        if (paymentMethod) {
            const accountBalancePayload = {
                "customerId": customerId,
                "amount": +data.amount,
                "transaction": paymentMethod,
                "paymentServiceId": (userRole?.name === 'Super Admin' ? dropValue : value )
            }
            await updateAccountBalance(accountBalancePayload).then(res => {

                if (res?.data?.status === "success") {
                    Snackbar.displayMsg("Account Balance Updated Successfully", ALERT_SEVERITY.SUCCESS);
                    setValue('amount', '');
                    setPaymentMethod('');
                    handleCreditDedit(false)
                    getSettlementList(initialPageOptions);
                } else {
                    Snackbar.displayMsg("Failed to update the account balance", ALERT_SEVERITY.ERROR);
                }
            })
        } else {
            Snackbar.displayMsg("Please Select the Payment Method", ALERT_SEVERITY.WARNING)
        }

    }

    columns[1].render = (data, rec) => (

        <>
            <span style={{ textTransform: 'lowercase' }}>{data != null ? convertToLocalTime(data) : ""}</span>
        </>
    )

    columns[6].render = (data, rec) => (
        <div style={{ textAlign: "right" }}>
            <span style={{ color: rec?.debitCredit === "Credit" ? "#009966" : "red" }}>
                {rec?.amount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}</span>
        </div>
    )

    columns[7].render = (data, rec) => (
        <div style={{ textAlign: "right" }}>
            <span>{rec?.availableBalance?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}</span>
        </div>
    )



    useEffect(() => {
        setSearchTerm("");
        setPageOptions(initialPageOptions);
        const xPageOptions = {
            ...initialPageOptions
        }
        setPageOptions(xPageOptions)
    }, [])
    React.useEffect(() => {
        setPageOptions((prevSt) => ({ ...prevSt, search: searchTerm }));
        if (searchTerm === "") {
            setPageOptions(initialPageOptions)
        }
        getSettlementList(pageOptions);
    }, [searchTerm]);
    useEffect(() => {
        if (customerId && dropValue && userRole?.name === 'Super Admin') {
            getSettlementList(initialPageOptions);  
        }
    },[dropValue,customerId])

    useEffect(() => {
        if (customerId && value && userRole?.name !== 'Super Admin') {
            getSettlementList(initialPageOptions);  
        }
    },[value,customerId])

    const onPageChange = (yPageOptions) => {
        const xPayload = {
            ...pageOptions,
            ...yPageOptions
        }
        // console.log("cPage", xPayload);
        setPageOptions(xPayload);
        getSettlementList(xPayload)
    }

    const handleCancelFilter = () => {
        // setFilters({ ...testRunInitialFilters });
        closeModal();
    }
    // useEffect(() => {
    //     getSettlementList(initialPageOptions);
    // }, [])

    const getSelectedFilter = (result) => {
        const selectedResults = _.filter(Object.keys(result), k => result[k])
        if (!_.isEmpty(selectedResults)) {
            //   return selectedResults
            //   return selectedResults[0]
            return selectedResults[0] === 'creditOrDebit' ? result[selectedResults] : selectedResults.join(', ');
        }
        return null
    }

    const handleSaveFilter = async () => {
        const options = {
            ...pageOptions,
            fromDate: filters?.selectDate?.selectDate[0] ? moment(new Date(filters?.selectDate?.selectDate[0].$d)).format() : null,
            toDate: filters?.selectDate?.selectDate[0] ? moment(new Date(filters?.selectDate?.selectDate[1].$d)).format() : null,
            debitOrCredit: getSelectedFilter(filters.debitOrCredit),
        };
        console.log("filtered options", filters.debitOrCredit);
        setPageOptions(options);
        await getSettlementList({
            ...options,
        });
        if (options.debitOrCredit === null && options.fromDate === null && options.toDate === null) {
            openModal()
            Snackbar.displayMsg("Please Select Any Values", ALERT_SEVERITY.ERROR)
        } else {
            closeModal()
        }

        // setFilters({ ...settlementActTransFilter });
    };

    const onClear = async () => {
        setFilters({ ...settlementActTransFilter });
        setPageOptions(initialPageOptions);
        await getSettlementList(initialPageOptions);
    };

    const handleSearchOnChange = (xValue) => {
        setSearchTerm(xValue);
        setPageOptions({ ...pageOptions, search: xValue });
        // refetchTestRunsQuery({ ...pageOptions, search: xValue });
    };

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Settlement Account Transactions"
                subtitle={"View the details of all the transactions here"}
                showSearch={{
                    show: true,
                    value: searchTerm,
                    onChange: handleSearchOnChange
                }}
                showFilter={{
                    show: true,
                    onClick: openModal
                }}
                showAvailableBalance={{
                    show: true
                }}
                showBalance={{
                    show: true,
                    value: data?.availableBalance?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })
                }}
                showAddRowBtn={{
                    show: true,
                    onClick: handleCreditDedit
                }}
            />
            <Box my={2} style={{ textTransform: 'none' }} width={"100%"} px={"0%"}>
                {/* <WrapperComponent isFetching={isLoading}> */}
                <PayaptTable
                    size={"small"}
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                    className={"standalone_table"}
                    scroll={{ y: "50vh" }}
                    columns={columns}
                    data={!_.isEmpty(data) ? data?.list : []}
                    loading={isLoading}
                    onChange={(pagination) =>
                        onPageChange(
                            {
                                page: pagination.current - 1,
                                search: "",
                                pageSize
                            })
                    }
                    pagination={{
                        pageSize,
                        current: !_.isEmpty(data) ? data.page + 1 : 1,
                        total: !_.isEmpty(data) ? data.count : 0,
                        size: 'small',
                        hideOnSinglePage: true,
                        showPrevNextJumpers: true,
                        // showTitle: true,
                    }}
                />
                {/* </WrapperComponent> */}
            </Box>

            <Box display={"flex"}>
                <MUIButton
                    startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                    sx={{ backgroundColor: '#F0F2F5', color: '#808080', mx: 3 }}
                    color="inherit"
                    title="Back"
                    onClick={() => {
                        updateClickedCard(false)
                        userRole?.name == 'Super Admin' ?
                            navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)
                    }
                    }
                />
            </Box>
            <Modal
                onCancel={() => handleCreditDedit(false)}
                open={isModalVisible} footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={"50%"}
            >
                <Box>
                    <TestCaseHeader
                        title="Credit or Debit Fund"
                    />
                    <Box px={3} mt={1}>
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="payment-method" name="payment-method" value={paymentMethod} onChange={handlePaymentChange}>
                                <FormControlLabel value="Credit" control={<Radio />} label="Credit" />
                                <FormControlLabel value="Debit" control={<Radio />} label="Debit" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box px={3} mt={2}>
                        <span style={{ textAlign: 'left', fontWeight: 600, fontSize: 18 }}>Amount<font color='red'>*</font></span>
                        <br />
                        <Controller
                            name="amount"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Amount is required',
                                pattern: { value: /^\d*\.?\d*$/, message: 'Enter a valid amount' },
                                validate: (value) => value > 0 || 'Amount cannot be negative or Zero',
                            }}
                            render={({ field }) => (
                                <>
                                    <PayaptTextField
                                        margin="dense"
                                        className="signin-text-box"
                                        placeholder="Enter the amount to credit or debit"
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleAmountChange(e); // You can call your custom logic here
                                        }}
                                        type="number"
                                    />
                                    {errors.amount && <p style={{ color: 'red', fontSize: 12 }}>{errors.amount.message}</p>}
                                </>
                            )}
                        />
                    </Box>
                    <Box mt={9} px={3}>
                        <MUIButton
                            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                            title="Submit"
                            onClick={handleSubmit(handleAccountBalance)}

                        />
                    </Box>
                </Box>
            </Modal>
            {open && <AntModal
                title="Filter Results for Settlement Account"
                open={open}
                width={'700px'}
                style={{ top: '15%', left: '22%' }}
                mask={false}
                okText="Apply Filter"
                cancelText="Clear"
                onOk={handleSaveFilter}
                footer={[]}
                okButtonProps={{
                    style: {
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: primaryColor
                    },
                    icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
                }}
                cancelButtonProps={{
                    style: {
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F0F2F5',
                        color: greyBorderColor
                    },
                    icon: <CancelIcon sx={{ fontSize: '18px' }} />
                }}
                onCancel={handleCancelFilter}
                closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
            >
                <Filter filters={filters} setFilters={setFilters} options={filterOptions} totalElements={data?.count} />

                {/* <Box >
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="payment-method" name="payment-method" value={paymentMethod} onChange={handlePaymentChange}>
                                <FormControlLabel value="Credit" control={<Radio />} label="Credit" />
                                <FormControlLabel value="Debit" control={<Radio />} label="Debit" />
                            </RadioGroup>
                        </FormControl>
                    </Box> */}

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} px={"2%"} >

                    <>
                        <MUIButton
                            startIcon={<CancelIcon style={{ fontSize: 18, color: greyBorderColor }} />}
                            title="Clear"
                            sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                            color="inherit"
                            onClick={onClear}
                        />
                        <MUIButton
                            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                            title="Apply Filter"
                            onClick={handleSaveFilter}
                            sx={{ mx: 2 }}
                        />
                    </>


                </Box>
            </AntModal>}
        </Box>
    )
}

export default SettlementAccount;
