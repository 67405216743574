import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { Col, Input, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import XSwitch from '../../shared/components/XSwitch';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveOrInactiveRTNMutation, useAddingRtnNumberMutation, useDeleteRtnNumberMutation, useGetMasterRtnQuery, useGetRtnNumberListMutation, useRountingNumberTypeQuery } from '../../redux/customers/customer.api';
import _ from 'lodash';
import MUIButton from '../../shared/components/MUIButton';
import { LeftCircleFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ALERT_SEVERITY, APP_ROUTE_KEYS, greyBorderColor, primaryColor } from '../../shared/constants/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TestCaseHeader from '../testCaseHeader';
import CancelIcon from '@mui/icons-material/Cancel';
import useModal from '../../shared/hooks/useModal.hook';
import AntModal from '../../shared/components/Modal/AntModal';
import { Controller, useForm } from 'react-hook-form';
import PayaptTextField from '../../shared/components/TextField';
import { addRoutingNUmberVAlidation, routingNumberValidation } from '../Customers/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { CancelOutlined } from '@mui/icons-material';
import AddRouting from '../Customers/AddRouting';
import Dropdown from '../../shared/components/Dropdown';
import PayaptTable from '../../shared/components/Table';
import { routingNumber_columns } from '../../constants/constants';
import { useMenusBasedOnPaymentMutation } from '../../redux/dashboard/dashboard.api';
import { updateIsBack } from '../../redux/common/common.slice';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const AddRoutingNumber = () => {
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const { customerId } = useSelector((state) => state?.common);
    // const { paymentServices } = useSelector((state) => state?.common);
    const { value, updateClickedCard, customer } = useContext(DropdownValueContext);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    // const customerId = location.state ? location.state.payload : null
    
    console.log("customer", customerId);
    const Snackbar = useContext(SnackbarContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const [viewRtnNumberList, { data, isLoading }] = useGetRtnNumberListMutation();
    const [activeOrInactiveRTN] = useActiveOrInactiveRTNMutation();
    const [deleteRtnNumber] = useDeleteRtnNumberMutation();
    const [addingRtnNumber] = useAddingRtnNumberMutation();
    const [rtnNumberList, setRtnNumberList] = useState();
    const [isModalVisible, setModalVisibility] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [paymentServices, setPaymentServices] = useState();
    const [isDelete, setIsDelete] = useState();
    const [rountingNumber, setRountingNumber] = useState();
    const resolver = yupResolver(addRoutingNUmberVAlidation);
    const applicableServices = paymentServices?.filter(service => service.isApplicable);
    const [dropValue, setDropValue] = useState();
    const { data: routingOptions } = useRountingNumberTypeQuery();
    const { data: masterRtnOptions, error } = useGetMasterRtnQuery({ paymentServiceId: dropValue, customerId: customerId });
    const [paymentList, setPaymentList] = useState();
    const [routingList, setRoutingList] = useState();
    const [masterList, setMasterList] = useState();
    const [getMenuData] = useMenusBasedOnPaymentMutation();
    const columns = routingNumber_columns;

    const { open, openModal, closeModal } = useModal();
    // const resolver = yupResolver(routingNumberValidation);
    const { control, register, formState: { errors }, handleSubmit, setValue, watch, reset
    } = useForm({
        resolver,
        defaultValues: {
            paymentServiceId: '',
            routingNumberType: '',
            masterRtnId: '',
            routingNumber: '',
            bankName: '',
            bankLocation: ''
        }
    });
    // console.log("rtnNumberList", rtnNumberList);
    const initialPageOptions = {
        customerId: customerId,
        pageNo: 0,
        pageSize,
        paymentServiceId: dropValue
    }
    const [pageOptions, setPageOptions] = React.useState({ ...initialPageOptions });

    const payload = {
        customerId: customerId,
        paymentServiceId: value,
    };
    const fetchData = async () => {
        const res = await getMenuData(payload);
        // console.log("ResMenu", res?.data?.paymentServices);
        setPaymentServices(res?.data?.paymentServices);
    }

    React.useEffect(() => {

        fetchData();
    }, []);

    useEffect(() => {
        if (dropValue) {
            viewRtnNumberList(initialPageOptions)
        }
    }, [dropValue])
    // React.useEffect(() => {
    //     if (data) {
    //         console.log("Second", data?.list);
    //         setRtnNumberList(data?.list)

    //     }
    // }, [data]);



    const onAddRowButtonClick = (xSelTC) => {
        setIsModalVisible2(true);
    }

    const updateValue = (key, val, index) => {
        const updatedXpathAndValues = [...rtnNumberList];
        updatedXpathAndValues[index] = {
            ...updatedXpathAndValues[index],
            // isUpdated: true,
            [key]: val
        }
        setRtnNumberList(updatedXpathAndValues);
    }

    const handleActiveCheck = async (isActive, routingNumberType, masterRtn, subRtn, respondentRtn) => {
        // setIsActive(event.target.checked === true ? 1 : 0)
        try {

            const payload = {
                customerId: customerId,
                routingNumber: respondentRtn || masterRtn || subRtn,
                isActive: isActive,
                paymentServiceId: value,
                routingNumberType: routingNumberType,
            }
            // console.log("Payload", payload,);

            await activeOrInactiveRTN(payload).unwrap().then((res) => {
                if (res?.successMessage === "RTN Status Updated Successfully") {
                    Snackbar.displayMsg("RTN Status Updated Successfully", ALERT_SEVERITY.SUCCESS);
                    viewRtnNumberList(initialPageOptions);

                } else {
                    Snackbar.displayMsg("RTN Status Failed to Update", ALERT_SEVERITY.ERROR);
                }
            })

        } catch (error) {
            console.error('Error updating active status:', error);
        }
    }

    const handleDeleteModal = (isDelete, rountingNumber, isVisible) => {
        setModalVisibility(isVisible)
        setIsDelete(isDelete)
        setRountingNumber(rountingNumber)
    }

    const handleDeleteRtnNumber = async () => {
        try {
            const payloadDelete = {
                customerId: customerId,
                routingNumber: rountingNumber,
                isDelete: isDelete,
                paymentServiceId: value,
            }
            // console.log("payloadDelete", payloadDelete);

            await deleteRtnNumber(payloadDelete).unwrap().then((res) => {
                if (res?.successMessage === "Customer RTN Deleted Successfully") {
                    Snackbar.displayMsg("Customer RTN Deleted Successfully", ALERT_SEVERITY.SUCCESS);
                    handleDeleteModal(false);
                    viewRtnNumberList(initialPageOptions);
                } else {
                    Snackbar.displayMsg("Customer RTN Failed to Delete", ALERT_SEVERITY.ERROR);
                }
            })

        } catch (error) {
            console.error('Error deleting the number:', error);
        }
    }

    const watchFieldForOrganization = watch('routingNumberType');
    // console.log("masterList", masterList);

    useEffect(() => {
        if ((watchFieldForOrganization === 2 || watchFieldForOrganization === 3) && error?.status === 400) {
            Snackbar.displayMsg(error.data.message, ALERT_SEVERITY.ERROR);
        }
    }, [watchFieldForOrganization, error]);



    const fetchPaymentService = () => {
        const paymentTypes = _.map(applicableServices, (m) => ({
            id: m.paymentServiceId,
            value: m.paymentServiceName,
            label: m.paymentServiceName,
        }));
        setPaymentList(paymentTypes);
    };

    const fetchRoutingNumber = () => {
        const routingTypes = _.map(routingOptions, (m) => ({
            id: m.routingNumberType,
            value: m.routingNumberTypeValue,
            label: m.routingNumberTypeValue,
        }));
        setRoutingList(routingTypes)
    };

    const fetchMasterRTN = () => {
        const masterTypes = _.map(masterRtnOptions, (m) => ({
            id: m.masterRtnId,
            value: m.masterRtn,
            label: m.masterRtn
        }));
        setMasterList(masterTypes)
    };


    React.useEffect(() => {
        fetchPaymentService();
        fetchRoutingNumber();
        fetchMasterRTN();
    }, [paymentServices, routingOptions, masterRtnOptions, dropValue]);


    const handleRtnNumberSubmit = async (data) => {
        // console.log("ADDRTN", data);
        try {
        if (watchFieldForOrganization !== 1) {
            data.masterRtnId = +data.masterRtnId;
        } else {
            // If it's not provided, ensure it doesn't remain as an empty string
            delete data.masterRtnId;
        }
        const addValues = _.filter(rtnNumberList, d => d.isNew).map(newRow => newRow.routingNumber);
        const xElpayload = {
            customerId: customerId,
            routingNumber: [data?.routingNumber],
            paymentServiceId: +data.paymentServiceId,
            bankName: data.bankName,
            bankLocation: data.bankLocation,
            ...(data.masterRtnId && { masterRtnId: +data.masterRtnId }),
            routingNumberType: +data.routingNumberType
        }
        // console.log("xElpayload", xElpayload);
        await addingRtnNumber(xElpayload).unwrap().then((res) => {
            console.log("RESSS", res);
            if (res?.status === "success") {
                Snackbar.displayMsg("Routing Number added or updated succesfully", ALERT_SEVERITY.SUCCESS);
                setIsModalVisible2(false)
                reset()
                setValue('routingNumber', "")
                setValue('paymentServiceId', "")
                setValue('bankName', "")
                setValue('bankLocation', "")
                setValue('masterRtnId', '')
                setValue('routingNumberType', "")
                viewRtnNumberList(initialPageOptions);
            } else {
                Snackbar.displayMsg("Routing Number is Failed to added or updated", ALERT_SEVERITY.ERROR);
            }

        })
        } catch (error) {
            if (error?.status === 400) {
                Snackbar.displayMsg(error?.data?.message, ALERT_SEVERITY.ERROR);
            } else {
                Snackbar.displayMsg("Routing Number is Failed to added or updated", ALERT_SEVERITY.ERROR);
            }
        }

    }
    const handleCancelRtn = () => {
        closeModal();
    };
    columns[5].render = (data, rec) => (
        // console.log("DATA", rec),
        <span>
            <XSwitch
                checked={rec?.isActive}
                // disabled={data?.isActive === 1}
                onChange={() => handleActiveCheck(!rec?.isActive === false ? 0 : 1, rec?.routingNumberType, rec?.masterRtn, rec?.subRtn, rec?.respondentRtn)}
                sx={{
                    // Define your styles here
                    '& .MuiSwitch-track': {
                        // backgroundColor: data.isActive ? '#009966 !important' : '#ACACAC !important',
                        backgroundColor: (rec.isActive ? '#009966 !important' : '#ACACAC !important'),
                        opacity: 1,
                    },
                }}
            />
        </span>
    )
    const onPageChange = (yPageOptions) => {
        const xPayload = {
            ...initialPageOptions,
            ...yPageOptions
        }
        setPageOptions(xPayload);
        viewRtnNumberList(xPayload)
    };
    useEffect(() => {
        if (paymentServices) {
            // Find the first applicable payment service or default to the first item
            const initialValue = paymentServices.find(service => service.isApplicable)?.paymentServiceId || paymentServices[0]?.paymentServiceId;
            setDropValue(initialValue);
        }
    }, [paymentServices]);

    const handleChange = (v) => {
        setDropValue(v)
    };
    const handleCancel = () => {
        setIsModalVisible2(false)
        setValue('routingNumber', "")
        setValue('paymentServiceId', "")
        setValue('bankName', "")
        setValue('bankLocation', "")
        setValue('masterRtnId', '')
        setValue('routingNumberType', "")

    }
    return (
        <>
            <TestCaseHeader
                title="Add Routing Number"
                showTchDropdown={{
                    show: false,
                }} />
            <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    {/* {(userRole?.name !== 'Super Admin' && */}
                    <Button
                        size="small"
                        startIcon={<AddCircleOutlineIcon size="small" />}
                        onClick={() => onAddRowButtonClick()}
                        // disabled={disableAddRowButton}
                        sx={{ textTransform: 'none', color: '#001A11' }}
                        disabled={userRole?.name !== 'Super Admin'}
                    >
                        Add RTN
                    </Button>
                    <Box >
                        <Dropdown
                            placeholder='Select Payment Service'
                            margin="dense"
                            className="user-dropdown"
                            style={{ width: '100%', height: '42px', }}
                            inputProps={{ 'aria-label': 'Without label' }}
                            MenuProps={styles.menuProps}
                            options={applicableServices}
                            valueKey='paymentServiceId'
                            labelKey='paymentServiceName'
                            onChange={handleChange}
                            value={dropValue}
                        />
                    </Box>
                    {/* )} */}

                </div>
                <Box mt={2}>
                    <PayaptTable
                        size={"small"}
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                        columns={columns}
                        className={"standalone_table"}
                        data={!_.isEmpty(data) ? data?.list : []}
                        loading={isLoading}
                        onChange={(pagination) =>
                            onPageChange({
                                pageNo: pagination.current - 1,
                                pageSize
                            })
                        }
                        pagination={{
                            pageSize,
                            current: !_.isEmpty(data) ? data.page + 1 : 1,
                            total: !_.isEmpty(data) ? data.count : 0,
                            size: 'small',
                            hideOnSinglePage: true,
                            showPrevNextJumpers: true,
                            showTitle: true,
                        }}
                        rowKey={(rec) => rec?.id}
                    />
                </Box>
               

                <Box my={2} mt={7} sx={{ display: 'flex', justifyContent: 'flex-start', mx:2 }} >
                    {(userRole?.name == 'Super Admin' ?
                        <>
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                color="inherit"
                                title="Back"
                                onClick={() => {
                                    updateClickedCard(false);
                                    dispatch(updateIsBack(true))
                                    userRole?.name == 'Super Admin' ?
                                        navigate(`${APP_ROUTE_KEYS.CUSTOMERS.CREATE_DATA}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)
                                }
                                }
                            />&nbsp;&nbsp;&nbsp;
                            {/* <MUIButton
                            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                            title="Save"
                            onClick={() => handleRtnNumberSubmit()}
                        /> */}
                        </>
                        :
                        <>
                            <MUIButton
                                startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                color="inherit"
                                title="Back"
                                onClick={() => {
                                    
                                    localStorage.setItem('clickedCard', false);
                                    userRole?.name == 'Super Admin' ?
                                        navigate(`${APP_ROUTE_KEYS.CUSTOMERS.CREATE_DATA}`) : navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`)
                                }
                                }
                            />&nbsp;&nbsp;&nbsp;
                            {/* <MUIButton
                            startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                            title="Save"
                            onClick={() => handleRtnNumberSubmit()}
                        /> */}
                        </>
                    )}


                </Box>
                <Modal
                    onCancel={() => handleDeleteModal(false)}
                    open={isModalVisible} footer={[]}
                    closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                    destroyOnClose
                    width={"30%"}
                >
                    <Box>
                        <Box mx={1}>
                            <Typography sx={{ fontWeight: 500, fontSize: 18 }}>Are you sure to delete ?</Typography>
                        </Box>
                        <Box mt={4} mx={1}>
                            <MUIButton
                                startIcon={<CancelIcon style={{ fontSize: 18 }} />}
                                title="No"
                                color="inherit"
                                sx={{ backgroundColor: '#F0F2F5', color: '#808080', marginRight: "10px", }}
                                onClick={() => handleDeleteModal(false)}
                            />
                            <MUIButton
                                startIcon={<CheckCircleIcon style={{ fontSize: 20, }} />}
                                title="Delete"
                                onClick={handleDeleteRtnNumber}
                            />
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    onCancel={handleCancel}
                    open={isModalVisible2}
                    footer={[]}
                    width={'650px'}
                    closeIcon={<CancelOutlined sx={{ color: "#001A11", backgroundColor: "white" }} />}
                    style={{ height: '710px', borderRadius: '20px' }}
                    destroyOnClose
                    maskClosable={false}
                >
                    {/* <AddRouting setModalVisibility={setIsModalVisible2}/> */}
                    <Box>
                        <TestCaseHeader
                            title='Add Routing Number'
                        />
                        <Box px={2.5} sx={{ overflowY: 'auto', maxHeight: '73vh', marginTop: '2%' }}>
                            <Grid item xs={6} container>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
                                    Select Payment Service <font color="red">*</font>
                                </Typography>
                                <Controller
                                    control={control}
                                    name={"paymentServiceId"}
                                    render={({ field }) => (
                                        <Dropdown
                                            inputRef={register('paymentServiceId', { required: true })}
                                            {...field}
                                            placeholder='Select Payment Service'
                                            margin="dense"
                                            className="user-dropdown"
                                            sx={{ marginTop: '7px' }}
                                            MenuProps={styles.menuProps}
                                            style={{ width: '120%', height: '56px' }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            options={paymentList}
                                            error={!!errors['paymentServiceId']}
                                            helperText={errors['paymentServiceId']?.message}
                                        />
                                    )}
                                />
                                {
                                    errors.paymentServiceId && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>
                                            Payment Service is required
                                        </div>
                                    )
                                }
                            </Grid>
                            <br />
                            <Grid item xs={6} container >
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
                                    Select Routing Number Type <font color="red">*</font>
                                </Typography>
                                <Controller
                                    control={control}
                                    name={"routingNumberType"}
                                    render={({ field }) => (
                                        <Dropdown
                                            inputRef={register('routingNumberType', { required: true })}
                                            {...field}
                                            placeholder='Select Routing Number Type'
                                            margin="dense"
                                            className="user-dropdown"
                                            sx={{ marginTop: '7px' }}
                                            MenuProps={styles.menuProps}
                                            style={{ width: '120%', height: '56px' }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            options={routingList}
                                            error={!!errors['routingNumberType']}
                                            helperText={errors['routingNumberType']?.message}
                                        />
                                    )}
                                />
                                {
                                    errors.routingNumberType && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>
                                            Routing Number Type is required
                                        </div>
                                    )
                                }
                            </Grid>
                            <br />
                            {(watchFieldForOrganization === 2 || watchFieldForOrganization === 3) &&
                                <Grid item xs={6} container>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
                                        Select Master RTN <font color="red">*</font>
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name={"masterRtnId"}
                                        render={({ field }) => (
                                            <Dropdown
                                                inputRef={register('masterRtnId', { required: false })}
                                                {...field}
                                                placeholder='Select Master RTN'
                                                margin="dense"
                                                className="user-dropdown"
                                                sx={{ marginTop: '7px' }}
                                                MenuProps={styles.menuProps}
                                                style={{ width: '120%', height: '56px' }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                options={masterList}
                                                error={!!errors['masterRtnId']}
                                                helperText={errors['masterRtnId']?.message}
                                            // valueKey='paymentServiceId'
                                            // labelKey='customerName'
                                            />
                                        )}
                                    />
                                    {errors.masterRtnId && (
                                        <div role="alert" style={{ color: '#DD2025', fontSize: '0.75rem' }}>
                                            Master RTN Type is required
                                        </div>
                                    )}
                                </Grid>
                            }
                            {(watchFieldForOrganization === 2 || watchFieldForOrganization === 3) &&
                                <br />
                            }
                            <Grid>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
                                    Routing Number <font color="red">*</font>
                                </Typography>
                                <Controller
                                    control={control}
                                    name={'routingNumber'}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register('routingNumber')}
                                            {...field}
                                            name="routingNumber"
                                            placeholder="Enter Routing Number"
                                            margin="dense"
                                            sx={{ width: '60%' }}
                                            error={!!errors['routingNumber']}
                                            helperText={errors['routingNumber']?.message}

                                        />
                                    )}
                                />
                            </Grid>

                            <Grid>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
                                    Bank Name <font color="red">*</font>
                                </Typography>
                                <Controller
                                    control={control}
                                    name={'bankName'}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register('bankName')}
                                            {...field}
                                            name="bankName"
                                            placeholder="Enter Bank Name"
                                            margin="dense"
                                            sx={{ width: '60%' }}
                                            error={!!errors['bankName']}
                                            helperText={errors['bankName']?.message}

                                        />
                                    )}
                                />
                            </Grid>

                            <Grid>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontSize: "17px", fontWeight: 'bold' }}>
                                    Bank Location <font color="red">*</font>
                                </Typography>
                                <Controller
                                    control={control}
                                    name={'bankLocation'}
                                    render={({ field }) => (
                                        <PayaptTextField
                                            {...register('bankLocation')}
                                            {...field}
                                            name="bankLocation"
                                            placeholder="Enter Bank Location"
                                            margin="dense"
                                            sx={{ width: '60%' }}
                                            error={!!errors['bankLocation']}
                                            helperText={errors['bankLocation']?.message}

                                        />
                                    )}
                                />
                            </Grid>

                        </Box>
                        <Box mt={3} px={2.5}>
                            <Grid container justifyContent="flex-start"  >
                                <MUIButton
                                    startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                                    title="Cancel"
                                    sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                                    color="inherit"
                                    onClick={handleCancel}
                                />&nbsp;&nbsp;&nbsp;&nbsp;
                                <MUIButton
                                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                                    title="Save"
                                    onClick={handleSubmit(handleRtnNumberSubmit)}
                                />
                            </Grid>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}



const RoutingValue = (props) => {
    const { rtn1, index, updateValue } = props
    const [isPrimary, setIsPrimary] = useState(0);
    const [isActive, setIsActive] = useState(0);

    const handlePrimaryCheck = (event) => {

        setIsPrimary(event.target.checked === true ? 1 : 0);
    }
    // const handleSendingCheck = (event) => {
    //     console.log("sending", event.target.checked === true ? 1 : 0);
    // }
    // const handleReceivingCheck = (event) => {
    //     console.log("receiving", event.target.checked === true ? 1 : 0);
    // }
    const handleActiveCheck = (event) => {

        setIsActive(event.target.checked === true ? 1 : 0)
    }

    return (
        <Row className='test-data-value-row'>
            <Col span={5} className='table-col-header'>
                <Input className='test-data-xpath-value' value={rtn1?.routingNumber}
                    readOnly
                    onChange={(e) => updateValue('routingNumber', e.target.value, index)} />
            </Col>
            <Col span={4} className='table-col-header'>
                <XSwitch
                    onChange={(event) => handleActiveCheck(event)}
                    checked={rtn1.isActive}
                    sx={{
                        // Define your styles here
                        '& .MuiSwitch-track': {
                            backgroundColor: rtn1.isActive ? '#009966 !important' : '#ACACAC !important',
                            opacity: 1,
                        },
                    }}
                />
            </Col>
            <Col span={2} className='table-col-header'>
                <DeleteOutlineIcon
                    onClick={() => handleDeleteRtnNumber()}
                    sx={{ color: '#676878' }}
                />
            </Col>
        </Row>
    )
}

export default AddRoutingNumber;
