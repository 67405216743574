import React, { useContext, useEffect } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import { useGetAdmi004BoardCastMessageQuery } from '../../redux/Reports/reports.api';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const BroadcastDropdown = (props) => {
    const { valuee, onChangeBroadCast ,messageType} = props;
    const { value } = useContext(DropdownValueContext);
    const {data:broadCastOptions} = useGetAdmi004BoardCastMessageQuery({messageType:messageType,paymentServiceId:value})

    useEffect(() => {
        if (!_.isEmpty(broadCastOptions)) {
            onChangeBroadCast(broadCastOptions?.boardCastMessageTypeId, broadCastOptions?.boardCastMessageTypeName)
        }
    }, [broadCastOptions])

    const onChange = (value) => {
        if (value) {
            const obj = _.find(broadCastOptions, m => m?.boardCastMessageTypeId === value);
            onChangeBroadCast(value, obj?.boardCastMessageTypeName)
        } else {
            onChangeBroadCast(value, value)
        }
    }
    return (
        <>
            <Dropdown
                placeholder=' Select Broadcast Messages'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={broadCastOptions}
                valueKey='boardCastMessageTypeName'
                labelKey='boardCastMessageTypeName'
                onChange={(e) => onChange(e)}
                value={valuee}
                MenuProps={styles.menuProps}
            />
        </>
    )
}

export default BroadcastDropdown;