import { Box, FormControl, FormControlLabel, Grid,  RadioGroup, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import BroadcastDropdown from './BroadcastDropdown';
import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RtnDropdown from './RtnDropdown';
import { Input, Modal, Radio, Space } from 'antd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useGetAdmi004MessageTypesQuery, useSystemMessageRequestMutation, useSystemMessageResponseMutation } from '../../redux/Reports/reports.api';
import _ from 'lodash';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';

const Admi004 = () => {
    const { TextArea } = Input;
    const { customerId } = useSelector((state) => state?.common);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const Snackbar = useContext(SnackbarContext);
    const {  customer, value } = useContext(DropdownValueContext);
    const data = useGetAdmi004MessageTypesQuery();
    const [selectedValue, setSelectedValue] = useState({});
    const [broadMsg, setBroadMsg] = useState();
    const [broadViewMsg, setBroadViewMsg] = useState();
    const [rtnNum, setRtnNum] = useState();
    const [isModalVisible, setModalVisibility] = useState(false);
    const [systemRequestGeneration] = useSystemMessageRequestMutation();
    const [systemResponse] = useSystemMessageResponseMutation();
    const [rtnRes, setRtnRes] = useState();
    const [admi004RetriveId, setAdmi004RetriveId] = useState();
    const [valuee, setValue] = useState([]);
    
    useEffect(()=>{
        if(data?.data){
        setSelectedValue(data?.data[0])
        }
    },[data])

    const handleReportChange = (event) => {
        // Update the state when a different radio button is selected
        setSelectedValue(event.target.value);
    };

    const onBroadCastSelection = (event) => {
        setBroadMsg(event)
    };
    const onBroadCastViewSelection = (event) => {
        setBroadViewMsg(event)
    };
    const onRTNSelection = (e) => {
        setRtnNum(e)
    };
    
    const responsePayload = {
        messageTypeName:selectedValue?.messageTypeName,
        boardcastMessageTypeName:selectedValue?.messageTypeName === 'Participant Broadcast' ? broadMsg :
        selectedValue?.messageTypeName === 'FedNow Broadcast' ? broadViewMsg : 
        null,
        customerId:(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
        rtn:rtnRes ? [rtnRes] : null,
        admi004RetriveIds:rtnRes ? null : admi004RetriveId
    }
    

    useEffect(() => {
        if (isModalVisible !==false) {
           systemResponse(responsePayload).then(res => {
            console.log("responsePayloadCCC",res);
            if (res?.error?.status === 400) {
                Snackbar.displayMsg("Response was Failed", ALERT_SEVERITY.ERROR)
            } else if (res?.data?.data?.length === 0) {
                Snackbar.displayMsg("No data received", ALERT_SEVERITY.ERROR);
            } else {
                setValue(res?.data?.data[0].responseXML);
            }
           }) 
        }
    },[isModalVisible])

    const systemMessagePayload = {
        messageTypeName:selectedValue?.messageTypeName,
        boardcastMessageTypeName:selectedValue?.messageTypeName === 'Participant Broadcast' ? broadMsg :
        selectedValue?.messageTypeName === 'FedNow Broadcast' ? broadViewMsg : 
        null,
        customerId:(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
        paymentServiceId:value
    }
    if (selectedValue?.messageTypeName === 'Participant Broadcast' && broadMsg !== "PING" && rtnNum !== "") {
        systemMessagePayload.rtn = rtnNum ? rtnNum : null;
    }
   
    const handleGenerate = async(isVisible) => {
        if (selectedValue?.messageTypeName === 'Participant Broadcast' ? broadMsg === undefined : broadViewMsg === undefined) {
           Snackbar.displayMsg("Please Select the Broadcast Message",ALERT_SEVERITY.ERROR);
           return; 
        }
         else {
            await systemRequestGeneration(systemMessagePayload).then(res => {
                console.log("Res", res);
                if (res?.data?.status === "success") {
                    Snackbar.displayMsg(res?.data?.message, ALERT_SEVERITY.SUCCESS);
                    setAdmi004RetriveId(res?.data?.admi004RetriveId);
                    setRtnRes(res?.data?.rtn);
                    setTimeout(() => {
                        setModalVisibility(isVisible);
                    }, 5000);
                } else if(res?.error?.status === 400){
                    Snackbar.displayMsg(res?.error?.data?.message, ALERT_SEVERITY.ERROR);  
                }else {
                    Snackbar.displayMsg("Failed to Generate System Message",ALERT_SEVERITY.ERROR);
                }
            })
           
        }
        
    };

    const onCancel = () => {
        setModalVisibility(false)
    };

    const downloadFile = () => {
        if (valuee) {
            const element = document.createElement("a");
            const file = new Blob([value], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = 
                (selectedValue?.messageTypeName === "Participant Broadcast" ? `Admi.004-${broadMsg}` : `Admi.004-${broadViewMsg}`)
            document.body.appendChild(element);
            element.click();
            setModalVisibility(true)   
        } else {
          Snackbar.displayMsg("No data to download",ALERT_SEVERITY.ERROR);  
        } 
    };
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Broadcast Messages (Admi.004)"
                showTestCustomer={{
                    show: true,
                }}
                showCustomer={{ show: false,  }}
            />

            <Box sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        {/* <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Report Type
                            </Typography>
                        </Grid> */}
                        <Grid item>
                            <Radio.Group>
                                <Space direction='horizontal' className='participation-value-container'>
                                    {
                                        _.map(data?.data, sp => (
                                            <div key={sp.messageTypeId} onClick={() => setSelectedValue(sp)}>
                                                <input
                                                    type='radio'
                                                    value={selectedValue}
                                                    className='participation-radio-item'
                                                    id={sp.messageTypeId}
                                                    defaultValue={selectedValue}
                                                    checked={selectedValue?.messageTypeId === sp.messageTypeId}
                                                />
                                                <label className={selectedValue?.messageTypeId === sp.messageTypeId ? 'input-radio-label-checked' : 'input-radio-label'} for={sp.messageTypeId}>{sp.messageTypeName}</label>
                                            </div>
                                        ))
                                    }
                                </Space>
                            </Radio.Group>
                            {/* <FormControl component="fieldset">
                                <RadioGroup row aria-label="payment-method" name="payment-method"
                                    value={selectedValue} onChange={handleReportChange}
                                >
                                    <FormControlLabel value="Request Broadcast" control={<Radio />} label="Request Broadcast" />
                                    <FormControlLabel value="View FedNow Broadcast" control={<Radio />} label="View FedNow Broadcast" />
                                    <FormControlLabel value="FedNow Participant File" control={<Radio />} label="FedNow Participant File (Admi.998)" />
                                </RadioGroup>
                            </FormControl> */}
                        </Grid>
                    </Grid>
                </Box>

                 {"Participant Broadcast" === selectedValue?.messageTypeName &&

                    <Box sx={{ px: '3.5%' }}>
                        <Box>
                            <Grid container>
                                <Grid item width={"40%"} mt={1}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                        Select Broadcast Messages
                                    </Typography>
                                </Grid>
                                <Grid item mt={3}>
                                    
                                    <BroadcastDropdown onChangeBroadCast={onBroadCastSelection} valuee={broadMsg} messageType={selectedValue?.messageTypeId}/>
                                </Grid>
                            </Grid>
                        </Box>
                        {("FPOF" === broadMsg || "FPON" === broadMsg)&& 
                        <Box>
                            <Grid container>
                                <Grid item width={"40%"} mt={2}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                        Select Routing Number
                                    </Typography>
                                </Grid>
                                <Grid item mt={4}>
                                    <RtnDropdown onChangeRtn={onRTNSelection} value={rtnNum} customer={(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer} paymentValue={value}/>
                                </Grid>
                            </Grid>
                        </Box>}

                    </Box> 


                } 
                 {"FedNow Broadcast" === selectedValue?.messageTypeName &&

                    <Box sx={{ px: '3.5%' }}>
                        <Box>
                            <Grid container>
                                <Grid item width={"40%"} mt={1}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                        Select Broadcast Messages
                                    </Typography>
                                </Grid>
                                <Grid item mt={3}>
                                   
                                    <BroadcastDropdown onChangeBroadCast={onBroadCastViewSelection} valuee={broadViewMsg} messageType={selectedValue?.messageTypeId}/>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>

                } 
            </Box>
            <Box mt={"20%"} sx={{ display: "flex", px: '2.1%' }}>
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                    title="Generate"
                    onClick={handleGenerate}
                />
            </Box>

            <Modal
                onCancel={onCancel}
                open={isModalVisible} footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={"70%"}
                maskClosable={false}
            >
                <Box>
                    <TestCaseHeader 
                    title={(selectedValue?.messageTypeName === "Participant Broadcast" ? `Admi.004-${broadMsg}` : `Admi.004-${broadViewMsg}`)}

                    showFileUpload={{
                        show: true,
                        customIcon: <FileDownloadIcon />,
                        onClick: () => downloadFile()
                    }}
                    // showRefresh={{
                    //     show: false,
                    //     onClick: handleRefreshButton
                    // }}
                    />
                    {/* <WrapperComponent isSuccess={isSuccess} isFetching={isLoading}> */}
                    <div style={{ marginTop: 5 }}>

                        <TextArea
                            className="xml-txt-area"
                            rows={15}
                            readOnly
                            style={{ height: '100%' }}
                        // value={value.length > 0 ? value[startIndex]?.response : 'IN PROGRESS'} // Display the response based on the current page
                            value={valuee === null ? "NO DATA" : valuee}
                        />


                        {/* <div style={{ marginTop: 4, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            
                            <Pagination
                                current={currentPage}
                                pageSize={itemsPerPage}
                                total={value?.length}
                                onChange={changePage}
                                hideOnSinglePage={true}
                            />
                           
                        </div> */}

                    </div>

                </Box>

            </Modal>
        </Box>
    )
}

export default Admi004;