import React, { useContext, useState } from 'react';
import { Grid, Checkbox, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { updateAuthCheckParams, updateUserId, updateUserMenu } from '../../redux/common/common.slice';
import { loginValidationSchema } from './utils';
import { useLoginMutation } from '../../redux/auth/auth.api';
import PayaptTextField from '../../shared/components/TextField';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { updateUserAuth } from '../../redux/auth/auth.slice';
import { SignInMsg } from '../../shared/constants/AlertMessages';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { appLogger } from '../../shared/utils';
import MUIButton from '../../shared/components/MUIButton';
import { menuItems } from '../../constants/constants';

const Signin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const resolver = yupResolver(loginValidationSchema);
    const Snackbar = useContext(SnackbarContext);

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver
    });

    const [showPassword, setShowPassword] = useState(false);
    const [login] = useLoginMutation();

    const handleNavigation = async (xRes) => {
        if (xRes && xRes?.token?.length > 0) {
            // console.log("xRes",xRes);
            const prvlgs = xRes?.userDetails?.menus;
            let menu = !_.isEmpty(prvlgs) ? _.find(menuItems, m => m.privilege === prvlgs[0]) : {};
            if(!_.isEmpty(menu?.children)){
                menu = menu.children[0]
            }
            // console.log('menu',menu);
            const xPayload = {
                isUserAuthenticated: true,
                isUserProfileCompleted: Number(window.REACT_APP_ACCOUNT_ACTIVATION_REQUIRED) == 0, // temporary true flag for testing the onBoarding routes
                enableCustomerOnboarding: Number(window.REACT_APP_ONBOARDING_REQUIRED) == 1,
                isUserOnBoardingCompleted: Number(window.REACT_APP_ONBOARDING_REQUIRED) == 0,
                selectedMenuKey: menu?.key,
                selectedSubMenuKey: menu?.parentKey,
                organizationName : xRes?.userDetails?.organizationName,
                customerId : xRes?.userDetails?.customerId,
                customerOrganizationName : xRes?.userDetails?.organizationName,
                customerTypeId: xRes?.userDetails?.customerTypeId,
                userMenu : xRes?.latestMenu,
                userDetails: {
                    // userName: `${xRes?.userDetails?.firstName} ${xRes?.userDetails?.lastName}`,
                    userRole: {
                        name: xRes?.userDetails?.userRoleName,
                        id: xRes?.userDetails?.userRoleId,
                        email: xRes?.emailId,
                        privileges: xRes?.userDetails?.menus
                    },
                },
                userName:`${xRes?.userDetails?.firstName} ${xRes?.userDetails?.lastName}`,
                paymentServices: xRes?.paymentServices

            }
            await dispatch(updateUserAuth(xRes));
            await dispatch(updateAuthCheckParams(xPayload));
            await dispatch(updateUserId(xRes?.userDetails.userId));
            await dispatch(updateUserMenu(xRes?.latestMenu))
            Snackbar.displayMsg(SignInMsg.SIGN_IN_SUCCESS, ALERT_SEVERITY.SUCCESS);
            // navigate(`${APP_ROUTE_KEYS.TEST_CASES}/SA`);
            // if(Number(window.REACT_APP_ACCOUNT_ACTIVATION_REQUIRED) == 1){
            //     navigate(`/accountactivation`);
            // } else if(Number(window.REACT_APP_ONBOARDING_REQUIRED) == 1) {
            //     navigate(`/customer/onboarding`);
            // } else {
                navigate(menu?.url);
            // }
            
        } else {
            Snackbar.displayMsg(`${xRes?.ErrorMessage}`, ALERT_SEVERITY.ERROR);
        }
    }

    const onSignIn = async (data) => {
        try {
            //TODO: Need Success or Fail proper response to add UI sanity checks //
            const result = await login(data).unwrap();
            handleNavigation(result);
        } catch (err) {
            appLogger("🚀 ~ file: signin.js:74 ~ onSignIn ~ err:", { err })
            Snackbar.displayMsg(SignInMsg.SIGN_IN_FAIL, ALERT_SEVERITY.ERROR);
        }
    }

    const onChangeForgot = () => {
        navigate('/forgotemail');
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid columns={1} style={{ marginTop: 50 }}>
            <Grid container spacing={1} style={{ textAlign: 'left' }}>
                <Grid lg={24} md={24} sm={24} xs={24} item className='login-grid-item'>
                    <span style={{ textAlign: 'left' }}>User ID</span>
                    <br />
                    <Controller
                        control={control}
                        name={"email"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("email")}
                                {...field}
                                name="email"
                                placeholder='Enter your email address'
                                id={'email'}
                                className='signin-text-box'
                                margin='dense'
                                error={!!errors['email']}
                                helperText={errors['email']?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid lg={24} md={24} sm={24} xs={24} item className='login-grid-item'>
                    <span>Password</span>
                    <br />
                    <Controller
                        control={control}
                        name={"password"}
                        render={({ field }) => (
                            <PayaptTextField
                                {...register("password")}
                                {...field}
                                name="password"
                                placeholder='Enter your password'
                                id={'password'}
                                margin='dense'
                                type={showPassword ? 'text' : 'password'}
                                className='signin-text-box'
                                error={!!errors['password']}
                                helperText={errors['password']?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid columns={1} mt={2} style={{ justifyContent: 'space-between', display: 'flex' }} className='login-grid-item'>
                <Grid style={{ marginTop: '-10px' }} lg={10} md={10} sm={10} xs={24} item>
                    <Checkbox />
                    <span className='login-remember' style={{ fontSize: '12px' }}>Remember me</span>
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={24} item>
                    <a style={{ fontSize: '12px' }} onClick={onChangeForgot}>Forgot Password?</a>
                </Grid>
            </Grid>
            <Grid columns={1} style={{ justifyContent: 'flex-end', display: 'flex' }} className='login-grid-item'>
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                    title="Sign In"
                    onClick={handleSubmit(onSignIn)}
                />
            </Grid>
        </Grid>
    )
}

export default Signin;
