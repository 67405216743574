import React, { useContext, useEffect, useState } from 'react'
import Dropdown from '../../shared/components/Dropdown'
import { useGetMessageTypeQuery } from '../../redux/testRuns/testRuns.api';
import _ from 'lodash';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { useSelector } from 'react-redux';

const styles = {
  menuProps: {
    sx: {
        '.MuiPaper-root': {
            maxHeight: '40%',
        },
    },
},
}

const MessageTypeDropdown = (props) => {
    const {valuee, onChangeMessage ,isClear} = props;
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { customerId } = useSelector((state) => state?.common);

    const { value, customer } = useContext(DropdownValueContext);
    const payload = {
      paymentServiceId:value,
      customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
    }
    const {data : messageOptions} = useGetMessageTypeQuery({paymentServiceId:value,customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer});
    useEffect(() => {
        if(!_.isEmpty(messageOptions)){
          onChangeMessage(messageOptions?.messageTypeId, messageOptions?.messageTypeName)
        }
    }, [messageOptions])

    const onChange = (value) => {
      // console.log('value-onChange',value);
      if(value){
        const obj = _.find(messageOptions, m => m?.messageTypeId === value);
        onChangeMessage(value, obj?.messageTypeName)
      } else {
        onChangeMessage(value, value)
      }
      
    }
  return (
    <>
     <Dropdown
       placeholder=' Select Message Type'
       margin="dense"
       className="user-dropdown"
       style={{ width: '100%', height: '42px',marginTop:'-20px'}}
       inputProps={{ 'aria-label': 'Without label' }}
       options={messageOptions}
       valueKey='messageTypeId'
       labelKey='messageTypeName'
       onChange={(e) =>onChange(e)}
       value={valuee}
       MenuProps={styles.menuProps}
       isClear={isClear}
     />
    </>
  )
}

export default MessageTypeDropdown;
