import { FORM_TYPES } from "../../../shared/constants/formTypes";

export const getFilterOptions = (messageTypes) => [
    {
        id: '1',
        categoryLabel: "Select Date",
        categoryName: "selectDate",
        subOptions: [
            {
                id: '1',
                filterName: 'selectDate',
                filterLabel: '',
                filterType: FORM_TYPES.ADD_DATE_PICKER
            }
        ]
    },
    {
        id: '2',
        categoryLabel: "Direction",
        categoryName: "direction",
        subOptions: [
            {
                id: '1',
                filterName: 'Outbound',
                filterLabel: 'Outbound',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'Inbound',
                filterLabel: 'Inbound',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
        ]
    },
    {
        id: '3',
        categoryLabel: "Trigger Message",
        categoryName: "triggerMessage",
        subOptions: [
            {
                id: '1',
                filterName: 'triggerMessage',
                filterLabel: 'Select Trigger Message',
                filterType: FORM_TYPES.ADD_DROP_DOWN,
                filterOptions: [
                    ...messageTypes,
                ]
            }
        ]
    },
    {
        id: '4',
        categoryLabel: "Result",
        categoryName: "result",
        subOptions: [
            {
                id: '1',
                filterName: 'Pass',
                filterLabel: 'Pass',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'Fail',
                filterLabel: 'Fail',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '3',
                filterName: 'In Progress',
                filterLabel: 'In Progress',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '4',
                filterName: 'Pending',
                filterLabel: 'Pending',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '5',
                filterName: 'Time out',
                filterLabel: 'Time out',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
        ]
    },
];

export const testRunInitialFilters = {
    selectDate: {
        selectDate: ''
    },
    testType: {
        MF: false,
        SA: false,
    },
    direction: {
        Inbound: false,
        Outbound: false,
    },
    triggerMessage: {
        triggerMessage: ''
    },
    result: {
        Success: false,
        fail: false,
        InProgress: false,
        pending: false,
        TimeOut : false
    }
}

export const settlementActTransFilter = {
    selectDate: {
        selectDate: ''
    }, 
    debitOrCredit:{
        Credit:false,
        Debit:false
    }
}

export const getSettlementFilterOptions = () => [
    {
        id: '1',
        categoryLabel: "Select Date",
        categoryName: "selectDate",
        subOptions: [
            {
                id: '1',
                filterName: 'selectDate',
                filterLabel: '',
                filterType: FORM_TYPES.ADD_DATE_PICKER
            }
        ]
    },
    {
        id: '2',
        categoryLabel: "Credit/Debit",
        categoryName: "debitOrCredit",
        subOptions: [
            {
                id: '1',
                filterName: 'creditOrDebit',
                filterLabel: '',
                filterType: FORM_TYPES.ADD_RADIO_BUTTON,
                filterOptions: [
                    { value: 'Credit', label: 'Credit' },
                    { value: 'Debit', label: 'Debit' },
                ]
            }
        ]
    } 
]
export const getUsersFilterOptions = () => [
    {
        id: '2',
        categoryLabel: "Status",
        categoryName: "status",
        subOptions: [
            {
                id: '1',
                filterName: 'Active',
                filterLabel: 'Active',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'Inactive',
                filterLabel: 'Inactive',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            }
        ]
    },
];


export const usersInitialFilters = {
    customerId: {
        customerId: ''
    },
    role: {
        role: ''
    },
    status: {
        Active: false,
        Inactive: false
    }
}

export const getCustomerFilterOptions = (messageTypes) => [

    {
        id: '3',
        categoryLabel: "Status",
        categoryName: "status",
        subOptions: [
            {
                id: '1',
                filterName: 'Active',
                filterLabel: 'Active',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'InActive',
                filterLabel: 'InActive',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '3',
                filterName: 'Pending',
                filterLabel: 'Pending',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            }
        ]
    },
];

export const customerInitialFilters = {
    customerType: {
        customerType: ''
    },
    // participationType: {
    //     participationType:''
    // },
    status: {
        Active: false,
        InActive: false,
        // Pending: false
    }
}

export const getActivityLogFilterOptions = (messageTypes) => [
    {
        id: '2',
        categoryLabel: "Select Date",
        categoryName: "selectedDate",
        subOptions: [
            {
                id: '1',
                filterName: 'selectedDate',
                filterLabel: '',
                filterType: FORM_TYPES.ADD_DATE_PICKER
            }
        ]
    }
];


export const activityLogInitialFilters = {
    customerId: {
        customerId: ''
    },
    activityTypeId: {
        activityTypeId: ''
    },
    selectedDate: {
        selectedDate: ''
    },
}

export const getMessageFilterOptions = (messageTypes) => [
    {
        id: '1',
        categoryLabel: "Test Type",
        categoryName: "testType",
        subOptions: [
            {
                id: '1',
                filterName: 'Message Flows (MF)',
                filterLabel: 'Message Flows (MF)',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'Standalone Message (SM)',
                filterLabel: 'Standalone Message (SM)',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
        ]
    },
    {
        id: '2',
        categoryLabel: "Direction",
        categoryName: "direction",
        subOptions: [
            {
                id: '1',
                filterName: 'Send',
                filterLabel: 'Send',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'Receive',
                filterLabel: 'Receive',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
        ]
    },
    {
        id: '3',
        categoryLabel: "Trigger Message",
        categoryName: "triggerMessage",
        subOptions: [
            {
                id: '1',
                filterName: 'triggerMessage',
                filterLabel: 'Select Trigger Message',
                filterType: FORM_TYPES.ADD_DROP_DOWN,
                filterOptions: [
                    ...messageTypes,
                ]
            }
        ]
    },
    {
        id: '4',
        categoryLabel: "Result",
        categoryName: "result",
        subOptions: [
            {
                id: '1',
                filterName: 'Success',
                filterLabel: 'Success',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'Fail',
                filterLabel: 'Fail',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '3',
                filterName: 'In Progress',
                filterLabel: 'In Progress',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            }
        ]
    },
];

export const testMessageInitialFilters = {
    testType: {
        MF: false,
        SA: false,
    },
    direction: {
        Inbound: false,
        Outbound: false,
    },
    triggerMessage: {
        triggerMessage: ''
    },
    result: {
        Success: false,
        fail: false,
        InProgress: false,
    }
}

export const simulatorTestDataInitialFilters = {
   
    type: {
        Customer: false,
        Bank: false
    }
}
export const getSimulatorTestDataFilterOptions = () => [
    {
        id: '1',
        categoryLabel: "Type",
        categoryName: "type",
        subOptions: [
            {
                id: '1',
                filterName: 'Customer',
                filterLabel: 'Customer',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            },
            {
                id: '2',
                filterName: 'Bank',
                filterLabel: 'Bank',
                filterType: FORM_TYPES.ADD_CHECK_BOX
            }
        ]
    },
];
