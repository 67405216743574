import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import TestCaseHeader from '../testCaseHeader';
import PayaptTextField from '../../shared/components/TextField';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import { useNavigate } from 'react-router-dom';
import MUIButton from '../../shared/components/MUIButton';
import { useGetConfigrationParametersDetailMutation, useUpdateConfigurationParametersDetailsMutation } from '../../redux/settings/settings.api';
import { useSelector } from 'react-redux';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import moment from 'moment';
import './settingStyles.css';
import { Controller, useForm } from 'react-hook-form';
import { numberValidationSchema } from './utils';
import TimezoneSelect from 'react-timezone-select';
import PayaptLoader from "../../shared/components/Spinner";
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import XTwoWayLabelSwitch from '../../shared/components/XTwoWayLabelSwitch';
import { LeftCircleFilled } from '@ant-design/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ConfigureParameter = () => {

    const snackbar = useContext(SnackbarContext);
    const { value, updateClickedCard, dropValue } = useContext(DropdownValueContext);
    const navigate = useNavigate();
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const resolver = yupResolver(numberValidationSchema);
    const { customerTypeId } = useSelector((state) => state?.common);
    const [transactionCCTDA, setTransactionCCTDA] = React.useState();
    const [transactionCCTDATCH, setTransactionCCTDATCH] = React.useState();
    const [transactionCCTCA, setTransactionCCTCA] = useState();
    const [responseTimeCCTDA, setResponseTimeCCTDA] = useState();
    const [responseTimeCCTCA, setResponseTimeCCTCA] = useState();
    const [responseTimeCCTFNS, setResponseTimeCCTFNS] = useState();
    const [msgGeneration, setMsgGeneration] = useState(false);
    const [camt035, setCamt035] = useState(false);
    const [msgNotification, setMsgNotification] = useState(false);
    const [eodDetailReport, setEodDetailReport] = useState(false);
    const [eodTotalReport, setEodTotalReport] = useState(false);
    const [eodParticipantReport, setEodParticipantReport] = useState(false);
    const [maxMessage, setMaxMessage] = useState();
    const [messageValidateDA, setMessageValidateDA] = useState(false);
    const [messageValidateCA, setMessageValidateCA] = useState(false);
    const [messageValidateFNS, setMessageValidateFNS] = useState(false);
    const customerId = useSelector((state) => state?.common?.customerId);
    const [getConfigrationParametersDetail, { data }] = useGetConfigrationParametersDetailMutation();
    const [updateConfigurationParametersDetails, { data: updateParameters, isLoading }] = useUpdateConfigurationParametersDetailsMutation();
    const [eodbodProcessTime, setEodbodProcessTime] = useState(null);
    const [eodbodProcessTimeZone, setEodbodProcessTimeZone] = useState({});


    const handleChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setNum(e.target.value);
        }
    };
    dayjs.extend(utc);

    dayjs.extend(timezone);
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {


        },
    });

    var savePayload = {
        "transactionLimitsCCTDebitorAgent":  parseInt(transactionCCTDA, 10),
        // "transactionLimitsCCTCreditorAgent": 500000,
        // "responseTimeCCTDebitorAgent": 5,
        "responseTimeCCTCreditorAgent": parseInt(responseTimeCCTCA, 10),
        "maxMessageSize": parseInt(maxMessage, 10),
        "customerId": customerId,
        // "responseTimeCCTFedNowSimulator": 5,
        "messageGeneration": msgGeneration,
        "accountCreditDebitNotification": msgNotification,
        "paymentServiceId": (userRole?.name === 'Super Admin' ? dropValue : value ),
        "camt035Activation":camt035,
        // "eodbodProcessingTime": eodbodProcessTime,
        // "ldtCountry": eodbodProcessTimeZone.value,
        // "ldtTimezone": eodbodProcessTimeZone.label,
        // "ldtTimeFormat": eodbodProcessTimeZone.abbrev,
        // "eodActivityDetailReport": eodDetailReport,
        // "eodActivityTotalReport": eodTotalReport,
        // "eodListOfParticipants": eodParticipantReport,
       
        // "messageValidationDebitorAgent": messageValidateDA,
        // "messageValidationCreditorAgent": messageValidateCA,
        // "messageValidationFedNowSimulator": messageValidateFNS,
       
       
        

    } 
    
       
    
    // console.log("savePayload", savePayload);
    const payload = {
        customerId:  customerId ,
        paymentServiceId:(userRole?.name === 'Super Admin' ? dropValue : value )
    }
    useEffect(() => {
        getConfigrationParametersDetail(payload)

    }, [])
    useEffect(() => {
        if (data) {
            console.log('getConfigrationParametersDetail', data);
            setTransactionCCTDA(data?.transactionLimits[0]?.customerCreditTransferDA)
            setTransactionCCTDATCH(data?.transactionLimits[0]?.customerCreditTransferDA)
            // setTransactionCCTCA(data?.transactionLimits[0].customerCreditTransferCA)
            setResponseTimeCCTDA(data?.responseTime[0]?.customerCreditTransferDA)
            setResponseTimeCCTCA(data?.responseTime[0]?.customerCreditTransferCA)
            setResponseTimeCCTFNS(data?.responseTime[0]?.customerCreditTransferFNS)
            setMsgGeneration(data?.messageGeneration[0]?.msgGen)
            setCamt035(data?.messageGeneration[0]?.camt35Activation)
            setMsgNotification(data?.accountCreditDebitNotification[0]?.actCreditDebitNotification)
            // setEodDetailReport(data?.EODReports[0]?.activityDetailsReport)
            // setEodTotalReport(data?.EODReports[0].activityTotalsReport)
            // setEodParticipantReport(data?.EODReports[0].listofParticipants)
            setMaxMessage(data?.messageSizeValidation[0]?.maxMessageSize)
            // setMessageValidateDA(data?.messageValidations[0].debtorAgent)
            // setMessageValidateCA(data?.messageValidations[0].creditorAgent)
            // setMessageValidateFNS(data?.messageValidations[0].fedNowSimulator)
            // setEodbodProcessTime(data?.EODProcessing[0].EODBODProcessingTime)
            setValue("transactionCCTDA", data?.transactionLimits[0].customerCreditTransferDAMax)
            setValue("transactionCCTDATCH", data?.transactionLimits[0].customerCreditTransferDA)
            // setValue("transactionCCTCA", data?.transactionLimits[0].customerCreditTransferCA)
            setValue("responseTimeCCTDA", data?.responseTime[0].customerCreditTransferDA)
            setValue("responseTimeCCTCA", data?.responseTime[0].customerCreditTransferCA)
            setValue("responseTimeCCTFNS", data?.responseTime[0].customerCreditTransferFNS)

        }
    }, [data])
    // console.log('transactionCCTDA', responseTimeCCTDA);

    const handleEodSave = async (res) => {
        // console.log("updateParameters", updateParameters);
        // updateConfigurationParametersDetails(savePayload).then(res => {
        if (res?.data?.status === 'success') {
            snackbar.displayMsg(res?.data?.message, ALERT_SEVERITY.SUCCESS)
        }
        else {
            snackbar.displayMsg('configration parameters failed update', ALERT_SEVERITY.ERROR)
        }
        // })
    }

    const handleConfigParameter = async () => {
        const updateConfigResults = await updateConfigurationParametersDetails(savePayload);
        handleEodSave(updateConfigResults);
    }


    // const apiResponse = data?.EODProcessing[0]?.EODBODProcessingTime;
    // const timezoneOptionFromApi = {
    //     value: data?.EODProcessing[0]?.ldtCountry,
    //     label: data?.EODProcessing[0]?.ldtTimezone,
    //     abbrev: data?.EODProcessing[0]?.ldtTimeFormat,
    // };
    // const [selectedTime, setSelectedTime] = React.useState(null);
    // const [timezone1, setTimezone] = useState('');
    // const [timezoneAbrev, setTimezoneAbrev] = useState('');

    // React.useEffect(() => {
    //     if (apiResponse) {
    //         const parsedTime = dayjs(apiResponse);
    //         // const parsedTime = dayjs.utc(apiResponse).local();
    //         console.log("parsed time", parsedTime);
    //         setSelectedTime(parsedTime);
    //         setEodbodProcessTime(apiResponse);
    //         // ------------------timezone from api------------
    //         console.log("timeeeezoneee from api", timezoneOptionFromApi);
    //         setTimezoneAbrev(timezoneOptionFromApi.abbrev)
    //         setTimezone(timezoneOptionFromApi);
    //         setEodbodProcessTimeZone(timezoneOptionFromApi)
    //     } else {
    //         console.log('API response is missing or invalid');
    //     }
    // }, [apiResponse]);

    // const handleTimeChange = (newValue) => {

    //     // console.log("timeeeeeeeeezone,", timezone1);
    //     console.log("changed time", newValue.format('YYYY-MM-DDTHH:mm:ss.SSS'));
    //     setSelectedTime(newValue);
    //     setEodbodProcessTime(newValue.format('YYYY-MM-DDTHH:mm:ss.SSS'));

    // };
    // const handleTimezoneChange = (selectedTimezone) => {

    //     // console.log("time zone", selectedTimezone);
    //     setEodbodProcessTimeZone(selectedTimezone)

    //     setTimezoneAbrev(selectedTimezone.abbrev)
    //     setTimezone(selectedTimezone.value);

    //     if (selectedTime) {
    //         // console.log("selected time", selectedTime);
    //         const convertedTime = dayjs(selectedTime).tz(selectedTimezone.value);
    //         // console.log("convertedTime", convertedTime);
    //         setSelectedTime(convertedTime);
    //         setEodbodProcessTime(convertedTime.format('YYYY-MM-DDTHH:mm:ss.SSS'));
    //     }
    // };


    const formatOptionLabel = (option) => {
        return `${option.label} (${option.abbrev})`;
    };

    const onBack = () => {
        userRole?.name == 'Super Admin' ?
            (updateClickedCard(false),
            navigate(`${APP_ROUTE_KEYS.CUSTOMERS.MASTER_SETTINGS}`))
            :
            (navigate(`${APP_ROUTE_KEYS.SETTINGS.MASTER}`))
    }
    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Configuration Parameters"
                displayInLine={true}
            />
            {isLoading && <PayaptLoader />}
            {/* <Box sx={{ marginTop: '20px', maxHeight: '60vh', overflowY: 'auto', width: "96%", mx: 2, paddingBottom: "20px" }} component={Paper}> */}
            <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                    Transaction Limits
                </Typography>
                <Grid container>

                </Grid>
                <Box>
                    <Grid container>
                        <Grid item mt={2} width={"40%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                Customer Credit Transfer (Debtor Agent)
                            </Typography>
                        </Grid>
                        {dropValue === 1 ? (
                            <Grid item >
                            <Controller
                                control={control}
                                name={"transactionCCTDA"}
                                // defaultValue={transactionCCTDA}
                                render={({ field }) => (
                                    // console.log("FIELD2", field),
                                    <PayaptTextField
                                        // {...register("transactionCCTDA")}
                                        {...field}
                                        name="transactionCCTDA"
                                        size="small"
                                        type="number"
                                        placeholder='Enter your value'
                                        margin='dense'
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setTransactionCCTDA(e.target.value);
                                        }}
                                        value={transactionCCTDA}
                                        error={transactionCCTDA ? (!!errors['transactionCCTDA']) : 'Max $500,000'}
                                        helperText={transactionCCTDA ? errors['transactionCCTDA']?.message || 'Max $500,000' : "Max $500,000"}
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                            // inputProp: { min: 0, max: 500000 },
                                            style: { width: '103%' }

                                        }}
                                        disabled={userRole?.name !== 'Super Admin' }
                                    // onChange={(e) => setTransactionCCTDA(e.target.value)}
                                    // value={field.value}
                                    />
                                )}
                            />

                        </Grid>
                        ):(
                            <Grid item >
                            <Controller
                                control={control}
                                name={"transactionCCTDATCH"}
                                // defaultValue={transactionCCTDA}
                                render={({ field }) => (
                                    // console.log("FIELD2", field),
                                    <PayaptTextField
                                        // {...register("transactionCCTDA")}
                                        {...field}
                                        name="transactionCCTDATCH"
                                        size="small"
                                        type="number"
                                        placeholder='Enter your value'
                                        margin='dense'
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setTransactionCCTDATCH(e.target.value);
                                        }}
                                        value={transactionCCTDATCH}
                                        error={transactionCCTDATCH ? (!!errors['transactionCCTDATCH']) : 'Max $1,000,000'}
                                        helperText={transactionCCTDATCH ? errors['transactionCCTDATCH']?.message || 'Max $1,000,000' : "Max $1,000,000"}
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                            style: { width: '103%' }

                                        }}
                                        disabled={userRole?.name !== 'Super Admin' }
                                    />
                                )}
                            />

                        </Grid>
                        )}
                        

                    </Grid>

                </Box>
                {/* <Box mt={-2}>
                    <Grid container>
                        <Grid item mt={2} width={"40%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                Customer Credit Transfer (Creditor Agent)
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Controller
                                control={control}
                                name={"transactionCCTCA"}
                                // defaultValue={transactionCCTCA}
                                render={({ field }) => (
                                    <PayaptTextField
                                        // {...register("transactionCCTCA")}
                                        {...field}
                                        name="transactionCCTCA"
                                        size="small"
                                        type="number"
                                        placeholder='Enter your value'
                                        margin='dense'
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setTransactionCCTCA(e.target.value);
                                        }}
                                        value={transactionCCTCA}
                                        error={transactionCCTCA ? (!!errors['transactionCCTCA']) : ''}
                                        helperText={transactionCCTCA ? errors['transactionCCTCA']?.message || 'Max $500,000' : "Max $500,000"}
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                            // inputProp: { min: 0, max: 500000 },
                                            style: { width: '103%' }

                                        }}
                                    // onChange={(e) => setTransactionCCTDA(e.target.value)}
                                    // value={field.value}
                                    />
                                )}
                            />
                        </Grid>

                    </Grid>

                </Box> */}
            </Box>
            <hr />


            {/* //response time */}
            <Box
                sx={{ px: '2%' }}
            >
                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                    Response Time
                </Typography>

                <Grid container  >
                    <Grid item width={"68%"} >
                        {/* <Box >
                            <Grid container>
                                <Grid item mt={2} width={"58.9%"}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                        Customer Credit Transfer (Debtor Agent)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <span style={{ display: "flex", marginTop: -20 }}>(seconds)</span>
                                    <Controller
                                        control={control}
                                        name={"responseTimeCCTDA"}
                                        // defaultValue={responseTimeCCTDA}
                                        render={({ field }) => (
                                            console.log("FIELD", field),
                                            <PayaptTextField
                                                {...register("responseTimeCCTDA")}
                                                {...field}
                                                name="responseTimeCCTDA"
                                                size="small"
                                                // type="number"
                                                placeholder='Enter your value'
                                                margin='dense'
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setResponseTimeCCTDA(e.target.value);
                                                }}
                                                value={responseTimeCCTDA}
                                                error={responseTimeCCTDA ? (!!errors['responseTimeCCTDA']) : ''}
                                                helperText={responseTimeCCTDA ? errors['responseTimeCCTDA']?.message : ""}
                                                InputProps={{
                                                    // startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                                    // inputProp: { min: 1, max: 20,step:1 },
                                                    style: { width: '110%' }

                                                }}

                                            />
                                        )}
                                    />
                                </Grid>

                            </Grid>

                        </Box> */}
                        <Box mt={-1}>
                            <Grid container>
                                <Grid item mt={2} width={"58.9%"}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                        Customer Credit Transfer (Creditor Agent)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <span style={{ display: "flex", marginTop: -20 }}>(seconds)</span>
                                    <Controller
                                        control={control}
                                        name={"responseTimeCCTCA"}
                                        // defaultValue={responseTimeCCTCA}
                                        render={({ field }) => (
                                            // console.log("FIELD", field),
                                            <PayaptTextField
                                                // {...register("responseTimeCCTCA")}
                                                {...field}
                                                name="responseTimeCCTCA"
                                                size="small"
                                                // type="number"
                                                placeholder='Enter your value'
                                                margin='dense'
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setResponseTimeCCTCA(e.target.value);
                                                }}
                                                value={responseTimeCCTCA}
                                                error={responseTimeCCTCA ? (!!errors['responseTimeCCTCA']) : "Response time (in seconds) is required"}
                                                // helperText={responseTimeCCTCA ? errors['responseTimeCCTCA']?.message : 'Response time (in seconds) is required'}
                                                helperText={!responseTimeCCTCA ? 'Response time (in seconds) is required' : errors['responseTimeCCTCA']?.message}
                                                InputProps={{
                                                    // startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                                    // inputProp: { min: 0, max: 500000 },
                                                    style: { width: '110%' }

                                                }}
                                                disabled={userRole?.name !== 'Super Admin'}
                                            />
                                        )}
                                    />
                                    {/* <PayaptTextField
                                            name="TlCctDa"
                                            size="small"
                                            // type="number"
                                            placeholder='Enter your value'
                                            margin='dense'
                                            // className='signin-text-box'
                                            // error={!!errors['email']}
                                            // helperText="Max $500,000"
                                            InputProps={{
                                                style: { width: '110%' }
                                                // startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                                // inputProp: { min: 0, max: 500000 }
                                            }}
                                            onChange={(e) => setResponseTimeCCTCA(e.target.value)}
                                            value={responseTimeCCTCA}
                                        /> */}
                                </Grid>

                            </Grid>

                        </Box>
                        {/* <Box mt={-1}>
                            <Grid container>
                                <Grid item mt={2} width={"58.9%"}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                        Customer Credit Transfer (FedNow Simulator)
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Controller
                                        control={control}
                                        name={"responseTimeCCTFNS"}
                                        // defaultValue={responseTimeCCTCA}
                                        render={({ field }) => (
                                            console.log("FIELD", field),
                                            <PayaptTextField
                                                {...register("responseTimeCCTFNS")}
                                                {...field}
                                                name="responseTimeCCTFNS"
                                                size="small"
                                                // type="number"
                                                placeholder='Enter your value'
                                                margin='dense'
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setResponseTimeCCTFNS(e.target.value);
                                                }}
                                                value={responseTimeCCTFNS}
                                                error={responseTimeCCTFNS ? (!!errors['responseTimeCCTFNS']) : ''}
                                                helperText={responseTimeCCTFNS ? errors['responseTimeCCTFNS']?.message : ''}
                                                InputProps={{
                                                    // startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                                                    // inputProp: { min: 0, max: 500000 },
                                                    style: { width: '110%' }

                                                }}

                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box> */}
                    </Grid>
                    {/* <Grid item px={"20px"}>
                        <Grid
                            sx={{

                                width: 250,
                                height: 'auto',
                                // position: 'absolute',
                                // right: "5px",
                                // top: 415,
                                padding: "5px",
                                backgroundColor: "#83f0cc",
                                opacity: [0.9, 0.8, 0.7],
                                borderRadius: "5px",
                                fontSize: "11px",

                            }}
                        >
                            <ul>
                                <li style={{ textAlign: "left" }}>20 Sec max </li>
                                <li style={{ textAlign: "left" }}>From the time of creation of request to response and the total time should not exceed 20                           sec</li>
                                <li style={{ textAlign: "left" }}>FI can configure response time multiples of 1 sec </li>
                                <li style={{ textAlign: "left" }}>1 Sec is min </li>
                                <li style={{ textAlign: "left" }}>5 sec is reserved for Creditor FI out of 20 Sec </li>
                                <li style={{ textAlign: "left" }}>If the gap between Debitor agent and FDN is more than 15 sec then FDN will reject the                             message</li>
                            </ul>
                        </Grid>
                    </Grid> */}
                </Grid>

            </Box>
            <hr />
            {/* message genration */}
            <Box mt={1} px={"2%"}>
                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                    Message Generation
                </Typography>
                <Box>

                    <Grid container>
                        <Grid item mt={2} width={"39%"}>
                            {value === 1 ?
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                    Generate ACCC after funds credited to account?
                                </Typography>
                                :
                                <>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                        Generate Camt.035 after funds credited to account?
                                    </Typography>
                                </>
                            }
                        </Grid>
                        <Grid item mt={2}>
                            {value || dropValue === 1 ?
                                <XTwoWayLabelSwitch
                                    checked={msgGeneration}
                                    onChange={(e) => setMsgGeneration(e.target.checked)}
                                    sx={{
                                        // Define your styles here
                                        '& .MuiSwitch-track': {
                                            backgroundColor: msgGeneration ? '#009966 !important' : '#ACACAC !important',
                                            opacity: 1,
                                        },
                                    }}
                                    disabled={userRole?.name !== 'Super Admin'}
                                />
                                :
                                <XTwoWayLabelSwitch
                                    checked={camt035}
                                    onChange={(e) => setCamt035(e.target.checked)}
                                    sx={{
                                        // Define your styles here
                                        '& .MuiSwitch-track': {
                                            backgroundColor: camt035 ? '#009966 !important' : '#ACACAC !important',
                                            opacity: 1,
                                        },
                                    }}
                                />
                            }


                        </Grid>

                    </Grid>
                </Box>
            </Box>
            {1 === customerTypeId &&
                <hr />
            }
            {1 === customerTypeId &&
                <Box mt={1} px={"2%"}>
                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                        Account Credit/Debit Notifications
                    </Typography>
                    <Box>

                        <Grid container>
                            <Grid item mt={2} width={"39%"}>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                    Account credit/debit notification (camt.054)
                                </Typography>
                            </Grid>
                            <Grid item mt={2}>
                                <XTwoWayLabelSwitch
                                    checked={msgNotification}
                                    onChange={(e) => setMsgNotification(e.target.checked)}
                                    sx={{
                                        // Define your styles here
                                        '& .MuiSwitch-track': {
                                            backgroundColor: msgNotification ? '#009966 !important' : '#ACACAC !important',
                                            opacity: 1,
                                        },
                                    }}
                                    disabled={userRole?.name !== 'Super Admin'}
                                />

                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            }
            {/* {1 === customerTypeId &&
                <hr />
            } */}
            {/* //eod  */}
            {/* <Box mt={1} px={"2%"}>
                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                    EOD Processing
                </Typography>
                <Box >
                    <Grid container>
                        <Grid item mt={2} width={"40%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                EOD/BOD Processing Time
                            </Typography>
                        </Grid>
                        <Grid item >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TimePicker
                                        key={timezone1}
                                        value={selectedTime}
                                        views={['hours', 'minutes', 'seconds']}
                                        onChange={handleTimeChange}
                                        ampm={false}
                                        format="HH:mm:ss"
                                        size="small"
                                        sx={{ width: '245px' }}
                                    />
                                    {timezoneAbrev && <span style={{ marginLeft: '-50%' }}>({timezoneAbrev})</span>}
                                </div>
                            </LocalizationProvider>
                            <TimezoneSelect
                                className="timezone-select"
                                value={timezone1}
                                onChange={handleTimezoneChange}
                                formatOptionLabel={formatOptionLabel}
                                placeholder="Select Timezone"

                            />

                        </Grid>

                    </Grid>

                </Box>
            </Box> */}

            {/* <hr /> */}

            {/* eod report */}
            {/* <Box mt={1} px={"2%"}>
                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                    EOD Reports
                </Typography>

                
                <Box>

                    <Grid container>
                        <Grid item mt={2} width={"39%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                Activity Details Report (camt.052)
                            </Typography>
                        </Grid>
                        <Grid item mt={2}>
                            <XTwoWayLabelSwitch
                                checked={eodDetailReport}
                                onChange={(e) => setEodDetailReport(e.target.checked)}
                                sx={{
                                    // Define your styles here
                                    '& .MuiSwitch-track': {
                                        backgroundColor: eodDetailReport ? '#009966 !important' : '#ACACAC !important',
                                        opacity: 1,
                                    },
                                }}
                            />

                        </Grid>

                    </Grid>
                </Box>
                <Box mt={2} display={'flex'}>

                    <Grid container>
                        <Grid item mt={2} width={"39%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                Activity Totals Report (camt.052)
                            </Typography>
                        </Grid>
                        <Grid item mt={2}>
                            <XTwoWayLabelSwitch
                                checked={eodTotalReport}
                                onChange={(e) => setEodTotalReport(e.target.checked)}
                                sx={{
                                    // Define your styles here
                                    '& .MuiSwitch-track': {
                                        backgroundColor: eodTotalReport ? '#009966 !important' : '#ACACAC !important',
                                        opacity: 1,
                                    },
                                }}
                            />

                        </Grid>

                    </Grid>
                </Box>
                <Box mt={2} display={'flex'}>


                    <Grid container>
                        <Grid item mt={2} width={"39%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                List of Participants (admi.998)
                            </Typography>
                        </Grid>
                        <Grid item mt={2}>
                            <XTwoWayLabelSwitch
                                checked={eodParticipantReport}
                                onChange={(e) => setEodParticipantReport(e.target.checked)}
                                sx={{
                                    // Define your styles here
                                    '& .MuiSwitch-track': {
                                        backgroundColor: eodParticipantReport ? '#009966 !important' : '#ACACAC !important',
                                        opacity: 1,
                                    },
                                }}
                            />

                        </Grid>

                    </Grid>
                </Box>
                
            </Box> */}

            {/* <hr /> */}
            {/* message validates */}

            {/* <Box mt={1} px={"2%"}>
                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                    Message Size validation
                </Typography>
                <Box >
                    <Grid container>
                        <Grid item mt={2} width={"40%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                Max Message Size(characters)
                            </Typography>
                        </Grid>
                        <Grid item >
                            <PayaptTextField
                                size="small"
                                onChange={(e) => setMaxMessage(e.target.value)}
                                value={maxMessage}
                                InputProps={{
                                    style: { width: '110%' }
                                }}
                            />
                        </Grid>

                    </Grid>

                </Box>
            </Box> */}

            {/* <hr /> */}

            {/* messsage validation */}

            {/* <Box mt={1} px={"2.2%"}>
                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                    Message Validations
                </Typography>

                <Box>


                    <Grid container>
                        <Grid item mt={2} width={"39%"}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                Debtor Agent
                            </Typography>
                        </Grid>
                        <Grid item mt={2}>
                            <XTwoWayLabelSwitch
                                checked={messageValidateDA}
                                onChange={(e) => setMessageValidateDA(e.target.checked)}
                                sx={{
                                    // Define your styles here
                                    '& .MuiSwitch-track': {
                                        backgroundColor: messageValidateDA ? '#009966 !important' : '#ACACAC !important',
                                        opacity: 1,
                                    },
                                }}
                            />

                        </Grid>

                    </Grid>
                    
                    <Box mt={2} display={'flex'}>

                        <Grid container>
                            <Grid item mt={2} width={"39%"}>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                    Creditor Agent
                                </Typography>
                            </Grid>
                            <Grid item mt={2}>
                                <XTwoWayLabelSwitch
                                    checked={messageValidateCA}
                                    onChange={(e) => setMessageValidateCA(e.target.checked)}
                                    sx={{
                                        // Define your styles here
                                        '& .MuiSwitch-track': {
                                            backgroundColor: messageValidateCA ? '#009966 !important' : '#ACACAC !important',
                                            opacity: 1,
                                        },
                                    }}
                                />

                            </Grid>

                        </Grid>
                    </Box>
                    <Box mt={2} display={'flex'}>

                        <Grid container>
                            <Grid item mt={2} width={"39%"}>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 100, fontSize: "14px" }}>
                                    FedNow Simulator
                                </Typography>
                            </Grid>
                            <Grid item mt={2}>
                                <XTwoWayLabelSwitch
                                    checked={messageValidateFNS}
                                    onChange={(e) => setMessageValidateFNS(e.target.checked)}
                                    sx={{
                                        // Define your styles here
                                        '& .MuiSwitch-track': {
                                            backgroundColor: messageValidateFNS ? '#009966 !important' : '#ACACAC !important',
                                            opacity: 1,
                                        },
                                    }}
                                />

                            </Grid>

                        </Grid>

                    </Box>
                </Box>
            </Box> */}
            {/* </Box> */}
            {/* buttons */}

            <Box sx={{ display: "flex", }} px={"2%"} mt={4}>
                {(userRole?.name === 'Admin' || userRole?.name === 'Tester') ?
                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        title="Back"
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                        color="inherit"
                        onClick={onBack}
                    />
                    
                    :
                <>
                    <MUIButton
                        startIcon={<LeftCircleFilled style={{ fontSize: 18 }} />}
                        title="Back"
                        sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
                        color="inherit"
                        onClick={onBack}
                    />
                    <MUIButton
                        startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
                        title="Save"
                        onClick={handleSubmit(handleConfigParameter)}
                        sx={{ mx: 2 }}
                    />
                </>
                 } 

            </Box>

        </Box >
    )
}

export default ConfigureParameter;