import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from "react-router-dom"
import { useSelector } from 'react-redux';
import React, { useEffect, useContext } from 'react';

import AuthenticatedRoutes from './containers/AuthenticatedRoutes';
import UnauthenticatedRoutes from './containers/loginRoutes';
import ProfileRoutes from './containers/profileRoutes';
import OnboardingRoutes from './containers/onboardingRoutes';

import './components/styles/variables.css'
import './components/styles/tableStyles.css'
import { SnackbarContext } from './shared/context/SnackbarContext.Context';
import SnackbarMui from './shared/components/SnackbarMui';
import { APP_ROUTE_KEYS } from './shared/constants/constants';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const snackbarCtx = useContext(SnackbarContext);

  const { isUserAuthenticated, isUserProfileCompleted, enableCustomerOnboarding, isUserOnBoardingCompleted } = useSelector((state) => state.common)

  useEffect(() => {
    if (location.pathname === '/') {
      if (!isUserAuthenticated && !enableCustomerOnboarding) {
        navigate('/signin')
      } else if ( !isUserOnBoardingCompleted && isUserAuthenticated && enableCustomerOnboarding) {
        navigate('/customer/onboarding');
      } else {
        //TODO: Actually this routes should be dashboard //
        navigate(`${APP_ROUTE_KEYS.DASHBOARD.MASTER}`);
      }
    }
  }, [location.pathname]);

  // console.log({ isUserAuthenticated, enableCustomerOnboarding, isUserProfileCompleted });

  const getRoutes = () => {
    if(isUserAuthenticated && isUserOnBoardingCompleted){
      return <AuthenticatedRoutes />
    }
    if (!isUserAuthenticated && !enableCustomerOnboarding) {
      return <UnauthenticatedRoutes />
    } if (isUserAuthenticated && !isUserProfileCompleted) {
      return <ProfileRoutes />
    } if (isUserAuthenticated && enableCustomerOnboarding) {
      return <OnboardingRoutes />
    }
    
  }
  return (
    <div className="App">
      {getRoutes()}
      {snackbarCtx.isDisplayed && <SnackbarMui />}
    </div>
  )
}

export default App;
