import React, { useEffect } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment, Typography } from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { InfoCircleFilled, } from '@ant-design/icons';

import PayaptTextField from '../shared/components/TextField';
import { greyBorderColor, primaryColor } from '../shared/constants/constants';
import { INPUT_HEIGHT } from '../shared/dims';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import MUIButton from '../shared/components/MUIButton';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import PayaptTooltip from '../shared/components/Tooltip';
import { colors } from '../shared/colors';
import PayaptDatePicker from '../shared/components/DatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { Roles } from '../constants/constants';
import { userValidationSchema, userValidationSchemaWithCustomer } from './Users/utils';
import { Controller, useForm } from 'react-hook-form';
import Dropdown from '../shared/components/Dropdown';
import { useFetchCustomerNameListMutation } from '../redux/users/users.api';
import CustomerDropdown from './Dashboard/CustomerDropdown';
import MessageTypeDropdown from './Dashboard/MessageTypeDropdown';
import DateFromToPicker from './Dashboard/DateRangePicker';
import InboundOutboundDropdown from './onboarding/InboundOutboundDropdown';
import CustomerBankDropdown from './onboarding/CustomerBankDropdown';
import BatchDropdown from './Dashboard/BatchDropdown';
// import MessageDropdown from './UploadTestCase/MessageDropdown';
import DirectionDropdown from './UploadTestCase/DirectionDropdown';
import UploadMessageDropdown from './UploadTestCase/UploadMessageDropdown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FedTchDropdown from './onboarding/FedTchDropdown';




const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // pl: 3,
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // pl: 1,
    pt: 1
  },
  title: {
    fontWeight: '600', fontSize: '1.2rem', pr: 1
  },
  subTitle: {
    fontSize: '0.85rem', fontStyle: 'italic', textAlign: 'left'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    // px: 4
  },
  addRowButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    mx: 0
    // width:
    // px: 4
  },
  actionsContainer1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // px: 4
  },
  iconButton: {
    borderRadius: 2,
    mx: 0,
  },
  runButtonStyle: {
    borderRadius: 2,
    mx: 1,
    backgroundColor: primaryColor,
    border: `2px solid ${primaryColor}`,
    marginTop: "3px",
    color: '#FFF',
    ':hover': {
      color: primaryColor,
      border: `2px solid ${primaryColor}`,
      // borderRadius: 2,
      backgroundColor: '#FFF',
      // p: '6px',
      // m: 0
    },
    ':disabled': {
      border: `2px solid #ACACAC`,
    }
  },
  highlightIcon: {
    color: primaryColor,
    ':hover': {
      color: '#FFF',
      backgroundColor: primaryColor,
    }
  },
  inputSx: {
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px 0px 0px 4px'
    },
    '.MuiInputBase-root': {
      height: INPUT_HEIGHT
    }
  },
  activeSearchIconSx: {
    height: 40,
    backgroundColor: '#DBDDE9',
    borderRadius: '0px 4px 4px 0px'
  },
  layoutSx: {
    marginLeft: '2%',
    marginRight: '2%',
    marginTop: '2%',
    width: '96%'
  }
}

const TestCaseHeader = (props) => {
  const { title, showInfoIcon = false, subtitle, showRefresh, showRun, showCreate,
    showSearch, showFilter, children, showUser, showFileUpload, showFileAdded, showFileDownload,
    showKillProcess, displayInLine = false, showDatepicker, showCustomer, showMessageType,
    showDateFromToPicker, showTestCustomer, showInboundOutbound, showInboundOutboundUpload,
    showCustomerBank, showBatchName, showSimTestSearch, showMessageDropdown, showDirectionDropdown, showAvailableBalance, showBalance, showAddRowBtn, showTchDropdown,showTchDropdownn } = props;
  const [searchBoxVisible, setSearchBoxVisible] = React.useState(false);
  const userData = useSelector((state) => state?.common?.userDetails);
  const { name: loggedInRole } = useSelector((state) => state?.common?.userDetails?.userRole)
  const [fetchCustomerNameList, { data: customersList }] = useFetchCustomerNameListMutation()
  const resolver = _.isEqual(_.toUpper(Roles.SUPER_ADMIN), _.toUpper(loggedInRole)) ? yupResolver(userValidationSchemaWithCustomer) : yupResolver(userValidationSchema);
  const handleSearchClick = () => {
    setSearchBoxVisible((xPreValue) => !xPreValue);
  }
  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver,


  });

  return (
    <Box width={"100%"}
      sx={displayInLine ? { ...styles.layoutSx, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } : { ...styles.layoutSx }}>
      <Box sx={styles.container}>
        <Box display={"flex"} flexDirection="row">
          <Typography sx={styles.title}>{title}</Typography>
          {showInfoIcon && <InfoCircleFilled className='step-info-icon' />}
        </Box>



        {showTestCustomer?.show ?
          <Grid container sx={{ justifyContent: 'flex-end' }} >
            <Grid item xs={8} sx={styles.childrenContainer}>
              <Typography sx={styles.subTitle}>{subtitle}</Typography>
            </Grid>
            <Grid item xs={4} sx={styles.actionsContainer}>
              {<Box sx={{ width: '55%', marginRight: '1%' }}>
                <FedTchDropdown
                // onChangeFedTch={showTchDropdown?.onChange} value={showTchDropdown?.value}
                />
              </Box>
              }
              {userData.userRole?.name === 'Super Admin' && showTestCustomer?.show && <Box sx={{ width: '50%', marginRight: '1%' }}>
                <CustomerDropdown onChangeCustomer={showTestCustomer?.onChange} value={showTestCustomer?.value} />
              </Box>}
            </Grid>
          </Grid> : <>
          </>
        }

        {showInboundOutbound?.show ?
          <Grid container sx={{ justifyContent: 'flex-end' }} >
            <Grid item xs={8} sx={styles.childrenContainer}>

              <Typography sx={styles.subTitle}>{subtitle}</Typography>


            </Grid>
            <Grid item xs={4} sx={styles.actionsContainer}>
              {showInboundOutbound?.show && <Box sx={{ width: '65%', marginRight: '1%' }}>
                <InboundOutboundDropdown onChangeInboundOrOutbound={showInboundOutbound?.onChange} value={showInboundOutbound?.value} />
              </Box>}
            </Grid>
          </Grid> :
          <></>
        }
        {showMessageDropdown?.show ?
          <Grid container sx={{ justifyContent: 'flex-end' }} >
            <Grid item xs={8} sx={styles.childrenContainer}>

              <Typography sx={styles.subTitle}>{subtitle}</Typography>


            </Grid>
            <Grid item xs={4} sx={styles.actionsContainer}>
              {
                showDirectionDropdown?.show &&
                <Box sx={{ width: '65%', marginRight: '1%' }}>
                  <DirectionDropdown onChangeDirection={showDirectionDropdown?.onChange} value={showDirectionDropdown?.value} isClear={showDirectionDropdown?.isClear} />
                </Box>
              }
              {showMessageDropdown?.show &&
                <Box sx={{ width: '65%', marginRight: '1%' }}>
                  <UploadMessageDropdown onChangeMessage={showMessageDropdown?.onChange} value={showMessageDropdown?.value} isClear={showMessageDropdown?.isClear} />
                </Box>
              }
              {showTchDropdownn?.show && <Box sx={{ width: '65%', marginRight: '1%' }}>
                <FedTchDropdown
                // onChangeFedTch={showTchDropdown?.onChange} value={showTchDropdown?.value}
                />
              </Box>
              }

            </Grid>
          </Grid> :
          <></>
        }


        {showSimTestSearch?.show ?
          <Grid container sx={{ justifyContent: 'flex-end' }} >
            <Grid item xs={8} sx={styles.childrenContainer}>

              <Typography sx={styles.subTitle}>{subtitle}</Typography>


            </Grid>
            <Grid item xs={4} sx={styles.actionsContainer}>
              {searchBoxVisible &&
                <PayaptTextField containerSx={{ mb: 0 }} sx={styles.inputSx} placeholder="Search here ..." value={showSimTestSearch?.value} onChange={(event) => showSimTestSearch?.onChange(event.target.value)} />}
              <IconButton sx={[styles.iconButton, searchBoxVisible && styles.activeSearchIconSx, { ml: 0, }]} onClick={handleSearchClick}>
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid> :
          <></>
        }
        {showSimTestSearch?.show && <>

        </>}


        {showCustomerBank?.show ?
          <Grid container sx={{ justifyContent: 'flex-end', marginBottom: '5px' }} >
            <Grid item xs={8} sx={styles.childrenContainer}>

              <Typography sx={styles.subTitle}>{subtitle}</Typography>


            </Grid>
            <Grid item xs={4} sx={styles.actionsContainer}>
              {showCustomerBank?.show && <Box sx={{ width: '50%', marginRight: '1%' }}>
                <CustomerBankDropdown onChangeCustomerBank={showCustomerBank?.onChange} value={showCustomerBank?.value} />
              </Box>}
            </Grid>
          </Grid> :
          <></>
        }

        {showAvailableBalance?.show ?
          <Grid container sx={{ justifyContent: 'flex-end' }} >
            <Grid item xs={8} sx={styles.childrenContainer}>

              <Typography sx={styles.subTitle}>{subtitle}</Typography>


            </Grid>
            <Grid item xs={4} sx={styles.actionsContainer1}>
              {showAvailableBalance?.show && <Box sx={{ width: '55%', marginRight: '1%', }}>
                <Typography sx={{ fontWeight: '600', fontSize: '1.2rem' }}>Available Balance</Typography>
                {/* <Typography>hii</Typography> */}
              </Box>}
              {showBalance?.show && <Box sx={{ width: '40%', marginRight: '1%', }}>
                <PayaptTextField
                  size={"small"}
                  InputProps={{
                    classes: {
                      input: 'right-aligned-input', // Define a custom class
                    },
                    // startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                    style: { width: '100%', }
                  }}
                  value={showBalance?.value}
                />
              </Box>}
            </Grid>
          </Grid> :
          <></>
        }


      </Box>
      <Box>
        <Grid container sx={{ justifyContent: 'flex-end' }}>
          {
            children &&
            <Grid item xs={8} sx={styles.childrenContainer}>
              {children}
            </Grid>
          }
          <Grid item xs={displayInLine ? 12 : 4} sx={{...styles.actionsContainer, marginTop:"8px"}}>
            {showBatchName?.show && <Box sx={{ width: '100%', marginRight: '1%', height: '20px' }}>
              <BatchDropdown onBatchNameChange={showBatchName?.onChange} valuee={showBatchName.value} customer={showBatchName.customer} />
            </Box>}
            {showSearch?.show && <>
              {searchBoxVisible &&
                <PayaptTextField containerSx={{ mb: 0 }} sx={styles.inputSx} placeholder="Search here ..." value={showSearch?.value} onChange={(event) => showSearch?.onChange(event.target.value)} />}
              <IconButton sx={[styles.iconButton, searchBoxVisible && styles.activeSearchIconSx, { ml: 0, }]} onClick={handleSearchClick}>
                <SearchIcon />
              </IconButton>
            </>}

            {showRefresh?.show && <IconButton sx={styles.iconButton} onClick={showRefresh?.onClick}>
              <RefreshIcon />
            </IconButton>}

            {showFilter?.show && <IconButton sx={styles.iconButton} onClick={showFilter?.onClick}>
              <FilterAltIcon />
            </IconButton>}

            {
              showAddRowBtn?.show &&
              <Box sx={styles.iconButton}>
                <Button
                  size="small"
                  startIcon={<AddCircleOutlineIcon size="small" />}
                  onClick={showAddRowBtn?.onClick}
                  // disabled={disableAddRowButton}
                  sx={{ textTransform: 'none', color: '#676878', }}
                >
                  Update Balance
                </Button>
              </Box>
            }

            {showFileUpload?.show && <IconButton sx={styles.iconButton} onClick={showFileUpload?.onClick} disabled={showFileUpload?.disabled}>
              {showFileUpload?.customIcon ? showFileUpload?.customIcon : <UploadFileIcon />}
            </IconButton>}

            {showFileDownload?.show && <IconButton sx={styles.iconButton} onClick={showFileDownload?.onClick} disabled={showFileDownload?.disabled}>
              {showFileDownload?.customIcon ? showFileDownload?.customIcon : <FileDownloadIcon />}
            </IconButton>}

            {showFileAdded?.show && <IconButton sx={styles.iconButton} onClick={showFileAdded?.onClick}>
              {showFileAdded?.customIcon ? showFileAdded?.customIcon : <NoteAddOutlinedIcon />}
            </IconButton>}

            {showCreate?.show && <IconButton sx={styles.iconButton} onClick={showCreate?.onClick} disabled={showCreate?.disabled}>
              <CreateNewFolderIcon />
            </IconButton>}

            {showRun?.show && <IconButton sx={styles.runButtonStyle} variant="contained" onClick={showRun?.onClick} disabled={showRun?.disabled} size='small'>
              <PlayArrowIcon />
            </IconButton>}

            {showUser?.show && <IconButton sx={styles.iconButton} onClick={showUser?.onClick}>
              <PersonAddAltOutlinedIcon />
            </IconButton>}

            {showKillProcess?.show &&
              <PayaptTooltip title='Kill Process'>
                <span>
                  <IconButton sx={styles.iconButton} onClick={showKillProcess?.onClick} disabled={showKillProcess?.disabled}>
                    <CancelPresentationIcon sx={{ color: showKillProcess?.disabled ? '#ACACAC' : colors.error.main }} />
                  </IconButton>
                </span>
              </PayaptTooltip>
            }


            {showTchDropdown?.show && <Box sx={{ width: '50%', marginRight: '1%' }}>
              <FedTchDropdown
              // onChangeFedTch={showTchDropdown?.onChange} value={showTchDropdown?.value}
              />
            </Box>
            }

            {showMessageType?.show &&
              <Box sx={{ width: '65%', marginRight: '1%' }}>
                <MessageTypeDropdown onChangeMessage={showMessageType?.onChange} valuee={showMessageType?.value} isClear={showMessageType?.isClear} />
              </Box>
            }

            {userData.userRole?.name === 'Super Admin' && showCustomer?.show && <Box sx={{ width: '45%', marginRight: '1%' }}>
              <CustomerDropdown onChangeCustomer={showCustomer?.onChange} value={showCustomer?.value} />

            </Box>}

            {showDateFromToPicker?.show &&
              <Box sx={{ width: '150%', marginRight: '1%' }}>
                <DateFromToPicker onChangeDateFrom={showDateFromToPicker?.onChange} value={showDateFromToPicker?.value} currentDate={showDateFromToPicker?.currentDate} disabledDate={showDateFromToPicker?.disabledDate} />
              </Box>
            }
            {showDatepicker?.show &&
              <Box sx={{ width: '75%' }}>
                <PayaptDatePicker onChangeDate={showDatepicker?.onChange} value={showDatepicker?.value} currentDate={showDatepicker?.currentDate} maxDate={showDatepicker?.maxDate} />
              </Box>}
            {showInboundOutboundUpload?.show && <Box sx={{ width: '50%', marginRight: '1%' }}>
              <InboundOutboundDropdown onChangeInboundOrOutbound={showInboundOutboundUpload?.onChange} value={showInboundOutboundUpload?.value} />
            </Box>}




          </Grid>

        </Grid>
      </Box>

    </Box>
  )
}

export default TestCaseHeader;