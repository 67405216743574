import { method } from 'lodash';
import { backendApi } from '../base.config';

export const customerApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
        updateCustomer: builder.mutation({
            query: (data) => ({
                url: 'customers/modifiedCustomerInformation',
                method: 'POST',
                body: data,
            }),
        }),
        addCustomer: builder.mutation({
            query: (data) => ({
                url: 'customers/addCustomerInformation',
                method: 'POST',
                body: data,
            }),
        }),
        getCustomerData: builder.mutation({
            query: (xargs) => {
                const { searchBoxInput, ...args } = xargs;
                    return {
                        url: `customers/customerList`,
                        method: 'POST',
                        body: { ...args },
                        refetchOnMountOrArgChange: true
                    }
            },

        }),
        getCustomerDataById: builder.mutation({
            query: (args) => ({
                url: `customers/getCustomerInfoById`,
                method: 'POST',
                params: { ...args },
            }),
        }),
        getCustomerTypeList: builder.query({
            query: () => ({
                method: "GET",
                url: `public/getCustomerType`,
            }),
            providesTags: [],
        }),
        getCustomerStatus: builder.query({
            query: () => ({
                method: "GET",
                url: `customers/getCustomerStatus`,
            }),
            providesTags: [],
        }),
        getCorrespondBankList: builder.query({
            query: () => ({
                method: "GET",
                url:`Settlement/correspondBackDropdown`,
            }),
        }),
        getRtnNumberList: builder.mutation({
            query: (args) => ({
                method:"POST",
                url:`Settlement/viewRtnNumberByCustomer`,
                params:{...args},
            }),
        }),
        getSettlementAccountList: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url:`Settlement/settlementAccountList`,
                body:payload
            }),
        }),
        updateAccountBalance: builder.mutation({
            query: (payload) => ({
                method:"POST",
                url:`Settlement/customerAccountBalanceUpdate`,
                body:payload,
            }),
        }),
        getRespondentBankDetails: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url:`Settlement/getRespondentBankDetails`,
                body:payload
            }),
        }),
        activeOrInactiveRTN: builder.mutation({
            query: (args) => ({
                method: "POST",
                url:`Settlement/activeOrInactiveRoutingNumber`,
                params:{...args}
            }),
        }),
        deleteRtnNumber: builder.mutation({
            query: (args) => ({
                method: "POST",
                url:`Settlement/deleteRtnNumber`,
                params: {...args}
            }),
        }),
        addingRtnNumber: builder.mutation({
            query: (payload) => ({
                method: "POST",
                url:`Settlement/updateSecondaryRtnNumber`,
                body:payload
            }),
        }),
        getReportDropdown: builder.query({
            query: ()=> ({
                method: "GET",
                url:`Settlement/getReportDropdown`,
            }),
        }),
        getPaymentService: builder.query({
            query: () => ({
                method: "GET",
                url:`customers/getPaymentService`
            }),
        }),
        rountingNumberType: builder.query({
            query: () => ({
                method:'GET',
                url:`public/routingNumberTypeDropdown`
            }),
        }),
        getMasterRtn: builder.query({
            query: (args) => ({
                method:"GET",
                url:`Settlement/getMasterRtn`,
                params:{...args}
            }),
        }),
        getPaymentByCustomer: builder.query({
            query: (args) => ({
                method:'GET',
                url:`customers/paymentServiceByCustomer`,
                params:{...args}
            }),
        }),
    }),
});

export const { 
    useUpdateCustomerMutation,
    useGetCustomerDataMutation, 
    useGetCustomerDataByIdMutation,
    useGetCustomerTypeListQuery, 
    useGetCustomerStatusQuery,
    useAddCustomerMutation,
    useGetCorrespondBankListQuery,
    useGetRtnNumberListMutation,
    useGetSettlementAccountListMutation,
    useUpdateAccountBalanceMutation,
    useGetRespondentBankDetailsMutation,
    useActiveOrInactiveRTNMutation,
    useDeleteRtnNumberMutation,
    useAddingRtnNumberMutation,
    useGetReportDropdownQuery,
    useGetPaymentServiceQuery,
    useRountingNumberTypeQuery,
    useGetMasterRtnQuery,
    useGetPaymentByCustomerQuery,
} = 
customerApi;