import React, { useContext } from 'react';
import { payapt_logo, payapt_text } from '../assets/base64/images';
import { LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { LanguageIcon, ProfileIcon, UserIcon } from '../constants/menuIcons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useModal from '../shared/hooks/useModal.hook';
import AntModal from '../shared/components/Modal/AntModal';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, ClickAwayListener, Grow, Menu, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import LockResetSharpIcon from '@mui/icons-material/LockResetSharp';
import { DropdownValueContext } from '../shared/context/DropdownProvider';

const AppHeader = (props) => {
    const navigate = useNavigate();
    const { isUserAuthenticated, onLogOut } = props;
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { value, clickedCard} = useContext(DropdownValueContext);
    const { userName } = useSelector((state) => state?.common);
    const { organizationName } = useSelector((state) => state?.common);
    const { customerOrganizationName } = useSelector((state) => state?.common);
    const { paymentServices } = useSelector((state) => state?.common);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleProfile = (event) => {
        setAnchorEl(event.currentTarget);
        // openModal()
        // navigate('/userProfile');
    };
    
    const getPaymentServiceNameById = (array, id) => {
        const service = array?.find(service => service.paymentServiceId === id);
        return service ? service.paymentServiceName : 'Service not found';
      };

      const idToFind = value; // Change this value to test different IDs
      const paymentServiceName = getPaymentServiceNameById(paymentServices, idToFind);
    

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileInfo = () => {
        navigate('/userProfile');
    };

    const handleChangePassword = () => {
        navigate('/changePassword');
    };


    const determineServiceToShow = () => {
        const fedNowService = paymentServices?.find(service => service.paymentServiceName === "FedNow");
        const tchService = paymentServices?.find(service => service.paymentServiceName === "TCH");
    
        // Both true, show none
        if (fedNowService?.isApplicable && tchService?.isApplicable) {
          return null;
        } else if (fedNowService?.isApplicable) {
          // Only FedNow is true
          return fedNowService.paymentServiceName;
        } else if (tchService?.isApplicable) {
          // Only TCH is true
          return tchService.paymentServiceName;
        }
    
        // If none are applicable or other conditions, return null or adjust as needed
        return null;
      };

    const serviceToShow = determineServiceToShow();
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {isUserAuthenticated &&
                <div style={{ backgroundColor: '#003322', display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'space-between' }}>
                    <div className='header-left-container'>{new Date().toString().substring(0, new Date().toString().indexOf('('))}</div>
                    <div className='header-right-container username-language-container'>
                        <div
                            onClick={handleProfile}
                            style={{ cursor: 'pointer' }} ><UserIcon /><span className='username-title'>{userName}</span> </div>
                        <div className='language-icon'>
                            <LanguageIcon />
                            <span className='language-title'>EN</span>
                        </div>
                    </div>
                </div>
            }
            <div className='header2-container'>
                <div className='header-left-container'>
                    <img src={`data:image/png;base64,${payapt_logo}`} />
                    <img src={`data:image/png;base64,${payapt_text}`} />
                </div>
                <div className='header-right-container'>
                    {
                        isUserAuthenticated ?
                            <>
                                {/* {serviceToShow && (
                                    <span style={{ color: '#808080', fontSize: 16, marginRight: '30px' }}>
                                        {serviceToShow}
                                    </span>
                                )} */}
                                {(userRole?.name === 'Admin' || userRole?.name === 'Tester') &&
                                    <span style={{ color: '#808080', fontSize: 16, marginRight: '20px' }}>{organizationName}</span>
                                }
                                {/* {(userRole?.name === 'Admin' || userRole?.name === 'Tester') &&
                                    <span style={{ color: '#808080', fontSize: 16, marginRight: '25px' }}>{paymentServiceName}</span>
                                } */}
                                {clickedCard &&
                                    <span style={{ color: '#808080', fontSize: 16, marginRight: '20px' }}>{customerOrganizationName}</span>
                                }
                                {/* {clickedCard &&
                                    <span style={{ color: '#808080', fontSize: 16, marginRight: '25px' }}>{paymentServiceName}</span>
                                } */}
                                <React.Fragment>
                                    <LogoutOutlined onClick={onLogOut} size={20} style={{ color: '#808080', fontSize: 20, cursor: 'pointer' }} />
                                    <span onClick={onLogOut} style={{ color: '#808080', fontSize: 16, cursor: 'pointer' }} >Logout</span>
                                </React.Fragment>
                            </>
                            :
                            <React.Fragment>
                                <div style={{ gap: '9px' }}>
                                    <QuestionCircleOutlined size={18} style={{ color: '#808080', fontSize: 18 }} />
                                </div>
                                <div>
                                    <span style={{ color: '#808080', fontSize: 16, height: '30px', fontWeight: 400, lineHeight: "50px" }} onClick={onLogOut}>Help</span>
                                </div>
                                <div className='language-icon'>
                                    <LanguageIcon />
                                </div>
                                <div>
                                    <span style={{ color: "#808080", fontSize: "16px", fontWeight: 300, top: "22px", lineHeight: "19px" }} >EN</span>
                                </div>
                            </React.Fragment>
                    }

                </div>
            </div>


            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                // onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            // transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleProfileInfo}>
                    <ProfileIcon />&nbsp; Profile Info
                </MenuItem>
                <MenuItem onClick={handleChangePassword}>
                    <LockResetSharpIcon sx={{ color: "#ACACAC", }} />&nbsp;Change Password
                </MenuItem>


            </Menu>
        </div>
    )
}

export default AppHeader;