import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from '@mui/material';
import { Modal } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditIcon } from '../../assets/base64/images';
import { getUsersFilterOptions, usersInitialFilters } from '../../components/homeScreens/TestRun/constants';
import { Roles, users_columns } from '../../constants/constants';
import { useFetchCustomerNameListQuery, useGetRoleListQuery, useGetUserDataMutation, useFetchCustomerNameListMutation } from '../../redux/users/users.api';
import Filter from '../../shared/components/Filter.popup';
import AntModal from '../../shared/components/Modal/AntModal';
import PayaptTable from '../../shared/components/Table';
import WrapperComponent from '../../shared/components/wrapperComponent';
import { greyBorderColor, primaryColor } from '../../shared/constants/constants';
import useModal from '../../shared/hooks/useModal.hook';
import { getSortDirection } from '../../utils/sorterUtils';
import '../styles/message.css';
import TestCaseHeader from "../testCaseHeader";
import './addEditUser';
import AddEditUser from './addEditUser';
import "./userStyles.css";
import { FORM_TYPES } from '../../shared/constants/formTypes';
import MUIButton from '../../shared/components/MUIButton';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';



const Users = () => {
  const pageSize = useSelector((state) => state?.common?.pageSize);
  const userData = useSelector((state) => state?.common?.userDetails);
  const { value } = useContext(DropdownValueContext);
  const [filterOptions, setFilterOptions] = useState(getUsersFilterOptions([]))
  const [filters, setFilters] = React.useState(usersInitialFilters || {});
  const { open, openModal, closeModal } = useModal();
  const [selectedRecord, setSelectedRecord] = useState({});
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isUserList, setIsUserList] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, serFilterStatus] = useState([]);
  const initialPageOptions = {
    "search": null,
    "page": 0,
    "size": pageSize,
    "paymentServiceId":value,
    "filters": {
      "status": null,
      "roles": null,
      "customerId": 0
    },
    "sortBy": null,
    "sortDir": null,
    // "sortCusName": null,
    // "sortFirstName": null,
    // "sortLastName": null
  }

  const [pageOptions, setPageOptions] = useState({ ...initialPageOptions });
  const [getUserData, { data, isLoading, isSuccess, isError }] = useGetUserDataMutation();
  const { data: roleData } = useGetRoleListQuery();
  const [fetchCustomerNameList, { data: customersList }] = useFetchCustomerNameListMutation()
  const cols = useMemo(() => {
    const columns = [...users_columns];
    if (_.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(userData?.userRole?.name))) {
      var sp_array = {
        title: 'Customer',
        width: '30%',
        dataIndex: 'customerName',
        key: 'customerName',
        sorter: true,
      }
      columns.unshift(sp_array)
    }
    columns[_.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(userData?.userRole?.name)) ? 7 : 6].render = (users, rec) => (
      <>
        <a style={{ display: "flex", flexDirection: "row" }} >
          <span onClick={() => handleClick(rec)}> <EditIcon /></span>
        </a>
      </>
    );
    return columns;
  }, [userData?.userRole?.name])

  const roleList = useMemo(() => {
    const filteredRoles = _.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(userData?.userRole?.name)) ? roleData :
      _.filter(roleData, r => !_.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(r?.user_role_name)))
    return filteredRoles;
  }, [userData?.userRole?.name, roleData])
  useEffect(() => {
    if (_.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(userData?.userRole?.name))) {
      fetchCustomerNameList();
    }
  }, [])

  const isFilterAdded = (category) => {
    const op = _.findIndex(filterOptions, f => f.categoryName === category);
    return op >= 0;
  }
  useEffect(() => {
    if (!_.isEmpty(customersList) && _.isEqual(_.toUpper(Roles.SUPER_ADMIN.name), _.toUpper(userData?.userRole?.name)) && !isFilterAdded('customerId')) {
      filterOptions.unshift({
        id: '0',
        categoryLabel: "Customer",
        categoryName: "customerId",
        subOptions: [
          {
            id: '0',
            filterName: 'customerId',
            filterLabel: 'Select Customer',
            filterType: FORM_TYPES.ADDED_DROP_DOWNN,
            filterOptions: _.map(customersList, (c, index) => ({
              id: index,
              value: c.customerId,
              label: c.customerName
            }))
          }
        ]
      })
      setFilterOptions(filterOptions)
    }
  }, [customersList])
  React.useEffect(() => {
    if (!_.isEmpty(roleList) && !isFilterAdded('role')) {
      const roles = _.map(roleList, (m, index) => ({
        id: index,
        value: m.user_role_id,
        label: m.user_role_name
      }))
      const obj = {
        id: '1',
        categoryLabel: "Role",
        categoryName: "role",
        subOptions: [
          {
            id: '1',
            filterName: 'role',
            filterLabel: 'Select Role',
            filterType: FORM_TYPES.ADD_DROP_DOWN,
            filterOptions: [
              ...roles,
            ]
          }
        ]
      }
      const isCustomerDropdownAdded = isFilterAdded('customerId');
      if (isCustomerDropdownAdded) {
        const arr = [filterOptions[0], obj, filterOptions[1]]
        setFilterOptions(arr);
      } else {
        filterOptions.unshift(obj);
        setFilterOptions(filterOptions);
      }
    }
  }, [roleList]);

  async function refetchTestRunsQuery(xOptions) {
    // if (!xIsBatch) {
      setPageOptions(xOptions);
      await getUserData({ ...xOptions });
    // }
  }

  React.useEffect(() => {
    setSearchTerm("");
    setPageOptions(initialPageOptions);
    const xPageOptions = {
      ...initialPageOptions
    }
    setPageOptions(xPageOptions);
  }, []);

  React.useEffect(() => {
    setPageOptions((prevSt) => ({ ...prevSt, search: searchTerm }));
    if (searchTerm === "") {
      setPageOptions(initialPageOptions)
    }
    // getUserData(pageOptions);
  }, [searchTerm]);

  useEffect(() => {
    if (isUserList) {
      getUserData(initialPageOptions); 
      setIsUserList(false)
    }
  },[isUserList])

  useEffect(() => {
    if (value) {
      getUserData(initialPageOptions)
    }
  },[value])

  const updatePageAndGetData = (options, sorter, pagination) => {
    // console.log("yPageOptions", pagination);
    // const xPayload = {
    //   ...pageOptions,
    //   ...yPageOptions
    // }
    var newPageOptions;
    if (sorter?.order) {
      newPageOptions = {
        ...pageOptions,
        page: pagination.current - 1,
        // ...pagination,
        sortBy: sorter.field,
        sortDir: sorter ? pageOptions.sortDir === 'asc' ? 'desc' : 'asc' : pageOptions.sortDir
      };
    }
    else {
      newPageOptions = {
        ...pageOptions,
        page: pagination.current - 1
        // ...pagination
      };
    }
    setPageOptions(prevOptions => ({
      ...prevOptions,
      ...newPageOptions
    }));
    // setPageOptions(xPayload);
    // setPageOptions(prevOptions => ({
    //   ...prevOptions,
    //   ...newPageOptions
    // }));
    // console.log("newPageOptions", newPageOptions);
    getUserData(newPageOptions);

  };
  const handleSearchOnChange = (xValue) => {
    setSearchTerm(xValue);
    setPageOptions({ ...pageOptions, search: xValue });
    refetchTestRunsQuery({ ...initialPageOptions, search: xValue });
  };

  const setIsModalVisible = (isVisible, rec, entity) => {

    if (isVisible && rec) {
      setSelectedRecord(rec);
    } else {
      setSelectedRecord({})
    }
    setModalVisibility(isVisible);
  }

  const handleSaveFilter = async () => {
    // const Role = filters.role.role == 'Admin' ? [1] : filters.role.role == 'Tester'? [2] :[1,2] ;
    serFilterStatus([]);
    if (filters.status.Active && !filters.status.Inactive) {
      filterStatus.push(1);
    }
    if (filters.status.Inactive && !filters.status.Active) {
      filterStatus.push(0);
    }
    if ((filters.status.Active && filters.status.Inactive)) {
      filterStatus.push(1, 0);
    }
    // console.log("filters-check",filters);
    const options = {
      ...initialPageOptions,
      filters: {
        ...pageOptions.filters,
        roles: filters?.role?.role ? filters?.role?.role : null,
        status: filterStatus?.length > 0 ? filterStatus : null,
        customerId: filters?.customerId?.customerId || 0
      }
    };
    setPageOptions(options);
    await getUserData({
      ...options
    });
    closeModal();
  };

  const handleCancelFilter = () => {
    // setFilters({ ...usersInitialFilters });
    closeModal();
  };

  const onClear = async() => {
    setFilters({ ...usersInitialFilters });
    setPageOptions(initialPageOptions);
   await getUserData(initialPageOptions);
    
  };
  const handleClick = (rec) => {
    if (rec.userId) {
      setIsModalVisible(true, rec, 'viewData')
    } else {
      setIsModalVisible(true, '', 'viewData')
    }

  }


  return (
    <Box>
      <TestCaseHeader
        title="User Management"
        subtitle="You can manage all the users here"
        showSearch={{
          show: true,
          value: searchTerm,
          onChange: handleSearchOnChange
        }}
        showUser={{
          show: true,
          onClick: handleClick
        }}
        showFilter={{
          show: true,
          onClick: openModal
        }}
        displayInLine={true}
      />

      <Box my={2} style={{ textTransform: 'none' }}>
        <WrapperComponent isSuccess={isSuccess} isFetching={isLoading} isError={isError}>
          <PayaptTable
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
            size="small"
            className="standalone_table"
            scroll={{ y: "50vh" }}
            columns={cols}
            data={!_.isEmpty(data) ? data?.list : []}
            loading={isLoading}
            rowKey={(rec) => rec?.userId}
            onChange={(pagination, filters, sorter) =>
              updatePageAndGetData({
                page: pagination.current - 1,
                search: '',
                sortBy: sorter?.field || pageOptions.sortBy,
                sortDir: getSortDirection(sorter.order) || pageOptions.sortDir,
                pageSize
              }, sorter, pagination)
            }
            pagination={{
              pageSize,
              current: !_.isEmpty(data) ? data.page + 1 : 1,
              total: !_.isEmpty(data) ? data.count : 0,
              showPrevNextJumpers: true,
              showTitle: true,
              hideOnSinglePage: true,
              size: "small",
            }}

          />
        </WrapperComponent>
      </Box>
      <Modal
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible} footer={[]}
        width={850} height={700}
        closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "#FFF" }} />}
        destroyOnClose
      >
        {
          <AddEditUser
            selectedRecord={selectedRecord}
            setModalVisibility={setModalVisibility}
            setIsUserList={setIsUserList}
            roleList={roleList}
            customersList={customersList}
          />
        }
      </Modal>

      {open && <AntModal
        title="Filter Results for User Management"
        open={open}
        width={'470px'}
        style={{ top: '23%', left: '28%' }}
        mask={false}
        okText="Apply Filter"
        cancelText="Clear"
        onOk={handleSaveFilter}
        footer={[]}
        okButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor
          },
          icon: <CheckCircleIcon sx={{ fontSize: '18px' }} />
        }}
        cancelButtonProps={{
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F0F2F5',
            color: greyBorderColor
          },
          icon: <CancelIcon sx={{ fontSize: '18px' }} />
        }}
        onCancel={handleCancelFilter}
        closeIcon={<CancelOutlinedIcon sx={{ color: "#001A11", backgroundColor: "white" }} />}
      >
        <Filter filters={filters} setFilters={setFilters} options={filterOptions} totalElements={data?.count} />

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} px={"2%"} >

          <>
            <MUIButton
              startIcon={<CancelIcon style={{ fontSize: 18, color: greyBorderColor }} />}
              title="Clear"
              sx={{ backgroundColor: '#F0F2F5', color: '#808080' }}
              color="inherit"
              onClick={onClear}
            />
            <MUIButton
              startIcon={<CheckCircleIcon style={{ fontSize: 18 }} />}
              title="Apply Filter"
              onClick={handleSaveFilter}
              sx={{ mx: 2 }}
            />
          </>


        </Box>
      </AntModal>}
    </Box>
  )
};

export default Users;
