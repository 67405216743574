import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CardIcon } from "../../assets/base64/images";
import MUIButton from "../../shared/components/MUIButton";
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from "../../shared/constants/constants";
import { useSelector } from "react-redux";
import { SnackbarContext } from "../../shared/context/SnackbarContext.Context";
import { DropdownValueContext } from "../../shared/context/DropdownProvider";


const styles = {
  
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      // px: 4
    },
  
}
const SettingCard = (props) => {
  const navigate = useNavigate();
  const { title = "", subTitle = "", linkText = "", urlPath = null, iconType,showEOD,children,displayInLine=false} = props;
  const { participationType } = useSelector(state => state?.common?.customerOnBoarding);
  const Snackbar = useContext(SnackbarContext);
  const {updateClickedCard} = useContext(DropdownValueContext);
  const { userRole } = useSelector((state) => state?.common?.userDetails);
  // console.log('xUrlPath',urlPath);
  const handleSettingCardClick = (xEvent, xUrlPath) => {
    // localStorage.setItem("clickedCard",true)
    if (userRole?.name === "Super Admin") {
       updateClickedCard(true)
    }
    xEvent.stopPropagation();
    console.log('xUrlPath-handleSettingCardClick',xUrlPath);
    if( xUrlPath === '/customers/settings/configureParticipationType'){
      // if(participationType?.participationTypeId != null){
        navigate(`${xUrlPath}`);
      // }
      // else{
        // Snackbar.displayMsg('Please select Participation Type',ALERT_SEVERITY.WARNING)
      // }
    }
    else{
      navigate(`${xUrlPath}`);
    }
    
  }
  const handleEODClick =(yEvent) => {
    yEvent.stopPropagation();
    navigate(`${APP_ROUTE_KEYS.CUSTOMERS.EOD_PROCESS_SETTINGS}`)
    
  }
//  console.log("showEOD",showEOD);
  return (
    <Box mt={1} ml={2}>
      <Card sx={{ backgroundColor: "#F5F7FA", minWidth: 330, minHeight: 190 }} onClick={(e) => handleSettingCardClick(e, urlPath)}>
        <Box my={2}>
          <Grid container justifyContent="space-between">
            <Grid item xs={3}>
              <span><CardIcon /></span>
            </Grid>
            <Grid item xs={2} >
            {
              showEOD&&
              <Box>
                <MUIButton
                 title="EOD Processing"
                 size="small"
                 sx={{ margin: "0px -30px",width:"150px"}}
                 onClick={handleEODClick}
                />
              </Box>
            }
        </Grid>
            <Grid item xs={3}>
              {iconType}
            </Grid>
          </Grid>
        </Box>
        <CardContent>
          <Typography mt={3}
            sx={{
              display: "flex",
              fontWeight: 600,
              fontSize: "18px",
              fontFamily: "Inter",
              fontStyle: "normal",
              textTransform: "capitalize",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              display: "flex", fontWeight: 400, fontSize: "18px",
              fontFamily: "Inter",
              fontStyle: "normal",
              textTransform: "capitalize",

            }}
          >
            {subTitle}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              fontWeight: 400,
              textDecorationLine: "underline",
              textDecorationColor: "#009966",
              fontSize: "18px",
              fontFamily: "Inter",
              fontStyle: "normal",
              textTransform: "capitalize",
            }}
          >
            <a>{linkText}</a>
          </Typography>
        </CardContent>
        <Grid container sx = {{ justifyContent: 'flex-end'}}>
        {
          children && 
        <Grid item xs={8} sx={styles.childrenContainer}>
          {children}
        </Grid>
        }
        </Grid>
        
      </Card>
    </Box>
  );
};

export default SettingCard;
