import * as yup from 'yup';

export const customerValidationSchema = yup.object().shape({
    customerName: yup.string().required("Organization Name is required"),
    customerType: yup.number().required("Organization Type is required"),
    // paymentServiceId: yup.number().required("Payment Service is required"),
    correspondentCustomerId: yup.string(),
    // participationTypeId: yup.number().required("Participation Type is required"),
    address1: yup.string().required("Address1 is required"),
    address2:yup.string().required("Address2 is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    postalCode: yup.string().required("Postal Code is required"),
    contactNumber: yup.string().required("Contact Number is required"),
    contactPerson: yup.string().required("Contact Person is required"),
    email: yup.string().email().required("Email Address is required"),
    // subscriptionPlanId: yup.number().required("Subscription Plan is required"),
    // routingNumber: yup.string().matches(/^[a-zA-Z0-9]{9}$/, 'RTN should contains 9 alphanumeric characters'),
    // registeredDate: yup.string().required("Registered Date is required"),
    // validityStartDate:yup.string().required('Validity Start Date is required'),
    // validityEndDate:yup.string().required('Validity End Date is required'),
    // paymentServiceId:yup.string().required("Payment Service is required"),
    status: yup.string().required("Status is required"),
    // routingNumber: yup.string().when('customerType', {
    //     is: 1,
    //     then: yup.string().matches(/^[a-zA-Z0-9]{9}$/, 'RTN should contain 9 alphanumeric characters'),
    //     otherwise: yup.string()
    // }),
    // routingNumber:yup
    // .string()
    // .test('isRoutingNumberRequired','RTN should contain 9 alphanumeric characters', function(value) {
    //     const customerType = this.resolve(yup.ref('customerType'));
    //     return customerType === 1 ? /^[a-zA-Z0-9]{9}$/.test(value) : true;
    // }),
    paymentServiceIds: yup.array()
        .of(yup.number().required('Payment service is required')) // Array of numbers (IDs)
        .min(1, 'Please select at least one payment service'),
    
});

export const routingNumberValidation = yup.object().shape({
    routingNumber: yup.string().matches(/^[a-zA-Z0-9]{9}$/, 'RTN should contains 9 alphanumeric characters'),
});

export const addRoutingNUmberVAlidation = yup.object().shape({
    paymentServiceId:yup.string().required("Payment Service is required"),
    routingNumberType:yup.string().required('Rounting Number Type is required'),
    routingNumber: yup
  .string()
  .nullable() // Allows null values
  .notRequired() // Makes the field optional
  .matches(/^[a-zA-Z0-9]{9}$/, 'RTN should contain 9 alphanumeric characters')
  .test(
    'is-valid-length',
    'RTN should contain 9 alphanumeric characters',
    (value) => !value || /^[a-zA-Z0-9]{9}$/.test(value)
  ),

    bankName: yup.string().required('Bank Name is required'),
    bankLocation:yup.string().required('Bank Location is required'),
    masterRtnId:yup.string(),
})

