import api from '../utils/api';
import { message } from 'antd'

export const GET_ALL_TEST_CASES_REQUEST = 'GET_ALL_TEST_CASES_REQUEST';
export const GET_ALL_TEST_CASES_SUCCESS = 'GET_ALL_TEST_CASES_SUCCESS';
export const GET_ALL_TEST_CASES_FAILURE = 'GET_ALL_TEST_CASES_FAILURE';
export const GET_ALL_TEST_SCENARIOS_REQUEST = 'GET_ALL_TEST_SCENARIOS_REQUEST';
export const GET_ALL_TEST_SCENARIOS_SUCCESS = 'GET_ALL_TEST_SCENARIOS_SUCCESS';
export const GET_ALL_TEST_SCENARIOS_FAILURE = 'GET_ALL_TEST_SCENARIOS_FAILURE';

//
export const GET_ALL_CUSTOMER_TC_VERSION_REQUEST = 'GET_ALL_CUSTOMER_TC_VERSION_REQUEST';
export const GET_ALL_CUSTOMER_TC_VERSION_SUCCESS = 'GET_ALL_CUSTOMER_TC_VERSION_SUCCESS';
export const GET_ALL_CUSTOMER_TC_VERSION_FAILURE = 'GET_ALL_CUSTOMER_TC_VERSION_FAILURE';


export const GET_ALL_TEST_RUNS_LIST_REQUEST = 'GET_ALL_TEST_RUNS_LIST_REQUEST';
export const GET_ALL_TEST_RUNS_LIST_SUCCESS = 'GET_ALL_TEST_RUNS_LIST_REQUEST';
export const GET_ALL_TEST_RUNS_LIST_FAILURE = 'GET_ALL_TEST_RUNS_LIST_FAILURE';
export const GET_ALL_TEST_CASES_OF_SCENARIO_REQUEST = 'GET_ALL_TEST_CASES_OF_SCENARIO_REQUEST';
export const GET_ALL_TEST_CASES_OF_SCENARIO_SUCCESS = 'GET_ALL_TEST_CASES_OF_SCENARIO_SUCCESS';
export const GET_ALL_TEST_CASES_OF_SCENARIO_FAILURE = 'GET_ALL_TEST_CASES_OF_SCENARIO_FAILURE';

export const GET_XPATH_VALUE_RETRIEVE_REQUEST = 'GET_XPATH_VALUE_RETRIEVE_REQUEST';
export const GET_XPATH_VALUE_RETRIEVE_SUCCESS = 'GET_XPATH_VALUE_RETRIEVE_SUCCESS';
export const GET_XPATH_VALUE_RETRIEVE_FAILURE = 'GET_XPATH_VALUE_RETRIEVE_FAILURE';

export const REVIEW_XPATH_DATA_REQUEST = 'REVIEW_XPATH_DATA_REQUEST';
export const REVIEW_XPATH_DATA_SUCCESS = 'REVIEW_XPATH_DATA_SUCCESS';
export const REVIEW_XPATH_DATA_FAILURE = 'REVIEW_XPATH_DATA_FAILURE';

export const GET_ALL_TEST_CASE_DETAILS_REQUEST = 'GET_ALL_TEST_CASE_DETAILS_REQUEST';
export const GET_ALL_TEST_CASE_DETAILS_SUCCESS = 'GET_ALL_TEST_CASE_DETAILS_SUCCESS';
export const GET_ALL_TEST_CASE_DETAILS_FAILURE = 'GET_ALL_TEST_CASE_DETAILS_FAILURE';

export const ADD_CUSTOMER_TEST_RUN_REQUEST = 'ADD_CUSTOMER_TEST_RUN_REQUEST';
export const ADD_CUSTOMER_TEST_RUN_SUCCESS = 'ADD_CUSTOMER_TEST_RUN_SUCCESS';
export const ADD_CUSTOMER_TEST_RUN_FAILURE = 'ADD_CUSTOMER_TEST_RUN_FAILURE';


export const REVIEW_XML_DATA_REQUEST = 'REVIEW_XML_DATA_REQUEST';
export const REVIEW_XML_DATA_SUCCESS = 'REVIEW_XML_DATA_SUCCESS';
export const REVIEW_XML_DATA_FAILURE = 'REVIEW_XML_DATA_FAILURE';


// 20.219.80.147
// const TESTCASES_URL  = 'https://api.payapt.com:8443/Payapt/api/v1' //latest url 
const TESTCASES_URL = 'http://172.173.157.190:8080/Payapt/api/v1'
// const TESTCASES_URL = 'http://20.219.80.147:8080/Payapt/api/v1' 
const headers = { 'Content-Type': 'application/json' };
const dispatchAction = (type, loading) => ({
    type,
    loading,
})

const serializePageOptions = (pageOptions) => {
    let str = '';
    const keys = Object.keys(pageOptions);
    _.mapKeys(keys, (key, index) => {
        str = `${str}${key}=${pageOptions[key]}`;
        if (_.size(keys) > Number(index) + 1) {
            str = `${str}&`
        }
    })
    return str;
}

// export const getAllTestCaseScenarios = (pageOptions) => async (dispatch) => {
//     console.log('~ getAllTestCaseScenarios ~ actionMethod ~', { pageOptions })
//     try {
//         dispatch(dispatchAction(GET_ALL_TEST_SCENARIOS_REQUEST, true))
//         const res = !_.isEmpty(pageOptions.searchBoxInput)
//             ? await api.get(`${TESTCASES_URL}/customerTcscenario/searchByCusTcName?${serializePageOptions(pageOptions)}`, headers)
//             : await api.get(`${TESTCASES_URL}/customerTcscenario/getCusTcScenario?${serializePageOptions(pageOptions)}`, headers)
//         dispatch(dispatchAction(GET_ALL_TEST_SCENARIOS_SUCCESS, false))
//         console.log('res', res.data)
//         return res.data;
//     } catch (error) {
//         console.log(error);
//         dispatch(dispatchAction(GET_ALL_TEST_SCENARIOS_FAILURE, true))
//     }
// }

// export const getAllCustomertcVersionById = (pageOptions) => async (dispatch) => {
//     console.log('getAllCustomertcVersionById ~ [pageOptions]:', pageOptions)
//     // http://152.67.24.51:8080/Payapt/api/v1/customertcversion/getAllCustomertcVersion?pageNo=0&pageSize=20&customerTestCaseId=63ea5e1b9b34215a61500dca
//     try {
//         dispatch(dispatchAction(GET_ALL_TEST_CASE_DETAILS_REQUEST, true))
//         // const res = !_.isEmpty(pageOptions.searchBoxInput)
//         //     ? await api.get(`${TESTCASES_URL}/customertcversion/getAllCustomertcVersion?${serializePageOptions(pageOptions)}`, headers)
//         //     : await api.get(`${TESTCASES_URL}/customertcversion/getCusTcScenario?${serializePageOptions(pageOptions)}`, headers)
//         const res = await api.post(`${TESTCASES_URL}/customertcversion/getAllCustomertcVersion?${serializePageOptions(pageOptions)}`, headers);
//         const payload = {
//             loadingState: false,
//             respData: res?.data
//         }
//         dispatch({ type: GET_ALL_TEST_CASE_DETAILS_SUCCESS, payload })
//         console.log('getAllCustomertcVersionById ~ [res]: ', res.data)
//         return res.data;
//     } catch (error) {
//         console.log(error);
//         dispatch(dispatchAction(GET_ALL_TEST_CASE_DETAILS_FAILURE, true))
//     }
// }

export const getAllTestCases = (pageOptions) => async (dispatch) => {
    try {
        dispatch(dispatchAction(GET_ALL_TEST_CASES_REQUEST, true))
        const res = await api.get(`${TESTCASES_URL}/customerTestcase/getAllCusTestCase?${serializePageOptions(pageOptions)}`, headers)
        dispatch(dispatchAction(GET_ALL_TEST_CASES_SUCCESS, false))
        return res.data;
    } catch (error) {
        console.log(error);
        dispatch(dispatchAction(GET_ALL_TEST_CASES_FAILURE, true))
    }
}

export const getAllCustomerTestRunsList = (pageOptions) => async (dispatch) => {
    try {
        const data = JSON.stringify(pageOptions)
        dispatch(dispatchAction(GET_ALL_TEST_RUNS_LIST_REQUEST, true))
        const res = await api.post(`${TESTCASES_URL}/customerTestRun/customerTestRunList`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        dispatch(dispatchAction(GET_ALL_TEST_RUNS_LIST_SUCCESS, false))
        // console.log('res.data', res.data)
        return res.data;
    } catch (error) {
        console.log(error);
        dispatch(dispatchAction(GET_ALL_TEST_RUNS_LIST_FAILURE, true))
    }
}
export const getAllTestCasesOfTheScenario = (pageOptions) => async (dispatch) => {
    try {
        dispatch(dispatchAction(GET_ALL_TEST_CASES_OF_SCENARIO_REQUEST, true))
        const res = await api.get(`${TESTCASES_URL}/customertcversion/getCustomerTestCaseVersion?${serializePageOptions(pageOptions)}`, headers)
        dispatch(dispatchAction(GET_ALL_TEST_CASES_OF_SCENARIO_SUCCESS, false))
        return res.data;
    } catch (error) {
        console.log(error);
        dispatch(dispatchAction(GET_ALL_TEST_CASES_OF_SCENARIO_FAILURE, true))
    }
}

export const getXpathAndValues = (pageOptions) => async (dispatch) => {
    try {
        dispatch(dispatchAction(GET_XPATH_VALUE_RETRIEVE_REQUEST, true))
        const res = await api.get(`${TESTCASES_URL}/customertestdata/getCustomerXpathAndValue?${serializePageOptions(pageOptions)}`, headers)
        dispatch(dispatchAction(GET_XPATH_VALUE_RETRIEVE_SUCCESS, false))
        return res.data;
    } catch (error) {
        console.log(error);
        dispatch(dispatchAction(GET_XPATH_VALUE_RETRIEVE_FAILURE, true))
    }
}

export const reviewTheXpathData = (payload) => async (dispatch) => {
    try {
        dispatch(dispatchAction(REVIEW_XPATH_DATA_REQUEST, true))
        const res = await api.post(`${TESTCASES_URL}/customertcversion/updateCustomerTestCaseVersion`, payload, headers)
        dispatch(dispatchAction(REVIEW_XPATH_DATA_SUCCESS, false))
        message.success('Reviewed successfully', [2])
        return res.data;
    } catch (error) {
        console.log(error);
        dispatch(dispatchAction(REVIEW_XPATH_DATA_FAILURE, true))
        message.error('Failed to review', [2])
    }
}

export const addCustomerTestRun = (payload) => async (dispatch) => {
    // console.log("addCustomerTestRunAPI OUT TRY", payload)
    try {
        console.log("addCustomerTestRunAPI IN TRY BF DIS")
        dispatch(dispatchAction(ADD_CUSTOMER_TEST_RUN_REQUEST, true))
        console.log("addCustomerTestRunAPI IN TRY AF DIS")
        const res = await api.post(`${TESTCASES_URL}/customerTestRun/addCustomerTestRun`, payload, headers)
        dispatch(dispatchAction(ADD_CUSTOMER_TEST_RUN_SUCCESS, false))
        message.success(res.data.messasge, [2])
        console.log("addCustomerTestRunAPI", res);
        return res.data;
    } catch (error) {
        console.log(error);
        dispatch(dispatchAction(ADD_CUSTOMER_TEST_RUN_FAILURE, true))
        message.error('Failed to review', [2])
    }
}
export const reviewTheXmlData = (payload) => async (dispatch) => {
    try {
        dispatch(dispatchAction(REVIEW_XML_DATA_REQUEST, true))
        const res = await api.get(`${TESTCASES_URL}/customerTcscenario/getXmlData?${serializePageOptions(payload)}`, headers)
        dispatch(dispatchAction(REVIEW_XML_DATA_SUCCESS, false))
        // message.success('Reviewed successfully', [2])
        return res.data;
    } catch (error) {
        // console.log('[reviewTheXmlData]', { error });
        dispatch(dispatchAction(REVIEW_XML_DATA_FAILURE, true))
        message.error('Failed to get XML', [2])
    }
}


