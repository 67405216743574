import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";
import './dashBoard.css';

const ProgressLine = (props) => {
    const { data } = props;
    // console.log('propsdata', props);
    return (
        <Box className="box-progressLine">
            <Typography className="header-progressLine">
                {data?.description}
            </Typography>
            <Box sx={{ flexDirection: 'column', marginTop: '10px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                    <Typography className="header-progressLine-inOut">
                        Inbound
                    </Typography>
                    <Typography className="smallText-progressLine">
                        • Total {data?.inbound}
                    </Typography>
                    <Typography className="smallText-progressLine-out">
                        • Reviewed  {data?.inboundReviewed}
                        {/* {DATA.received} */}
                    </Typography>
                </Box>
                <Typography>
                    {data?.inboundPercentage}
                    <span style={{ fontSize: 10 }}>%</span></Typography>
                <LinearProgress sx={{
                    backgroundColor: '#BFFFFF',
                    "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#30C9C9",
                    }, marginBottom: '7px'
                }} variant="determinate" value={data?.inboundPercentage}
                // {DATA.inbound} 
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                    <Typography className="header-progressLine-inOut">
                        Outbound
                    </Typography>
                    <Typography className="smallText-progressLine">
                        • Total {data?.outbound}
                    </Typography>
                    <Typography className="smallText-progressLine-out">
                        • Reviewed {data?.outboundReviewed}
                    </Typography>
                </Box>
                <Typography>
                    {data?.outboundPercentage}
                    <span style={{ fontSize: 10 }}>%</span></Typography>
                <LinearProgress sx={{
                    backgroundColor: '#FFC3BF',
                    "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#D65442",
                    },
                }} variant="determinate" value={data?.outboundPercentage}
                // {DATA.outbound} 
                />
            </Box>
        </Box>
    )
}

export default ProgressLine